import { sharedService } from "../_services";
import { alertActions } from "./";

export const sharedActions = {
  getAll,
};

function getAll() {
  return (dispatch) => {
    return sharedService.getAll().then(
      (user) => {
        dispatch(success(user));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function success(data) {
    return { type: "SHARED_LIST_SUCCESS", data };
  }
}
