import { costCenterConstants } from "../_constants";

const initialState = {
  newCostCenter: {},
  costCenters: [],
  activeCostCenter: {},
};

export function costCenters(state = initialState, action) {
  switch (action.type) {
    case costCenterConstants.NEW_COSTCENTER_SUCCESS:
      return { ...state };
    case costCenterConstants.NEW_COSTCENTER_FAILURE:
      return { ...state };
    case costCenterConstants.COSTCENTER_LIST_SUCCESS:
      return {
        ...state,
        costCenters: action.costCenters,
      };
    case costCenterConstants.GET_COSTCENTER_SUCCESS:
      return {
        ...state,
        activeCostCenter: action.costCecnter,
      };
    case costCenterConstants.COSTCENTER_LIST_FAILURE:
    default:
      return { ...state };
  }
}
