import config from "./config.json";
import { authHeader } from "../_helpers";
config.apiUrl = process.env.REACT_APP_API_URL

export const couponCodeService = {
  add,
  getAll,
  getById,
  update,
  deleteById: _delete,
};

function getAll() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}/coupon-codes`, requestOptions).then(
    handleResponse
  );
}

function getById(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}/coupon-codes/${id}`, requestOptions).then(
    handleResponse
  );
}

function add(couponCode) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(couponCode),
  };

  return fetch(`${config.apiUrl}/coupon-codes`, requestOptions).then(
    handleResponse
  );
}

function update(couponCode) {
  const requestOptions = {
    method: "PATCH",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(couponCode),
  };

  return fetch(
    `${config.apiUrl}/coupon-codes/${couponCode.id}`,
    requestOptions
  ).then(handleResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}/coupon-codes/${id}`, requestOptions).then(
    handleResponse
  );
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        localStorage.removeItem("user");
        // location.reload(true);
      }

      const error = (data && data.error) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
