export function markInvalidPackages(_package) {
  _package.isValid = true;

  _package.trips = _package.trips.filter((trip) => {
    return new Date(trip.startDate).getTime() > new Date().getTime();
  });

  if (_package.trips.length === 0) {
    _package.isValid = false;
    return _package;
  }

  for (var v = 0; v < _package.trips.length; v++) {
    const trip = _package.trips[v];
    const travelCostCenters = trip.costCenters.filter(
      (costCenter) => costCenter.costType === "TravelType"
    );
    let travelTypes = trip.travelTypes.sort();
    if (travelTypes.length !== travelCostCenters.length) {
      _package.isValid = false;
      break;
    }

    const cTravelTypes = travelCostCenters
      .map((costCenter) => {
        return costCenter.travelType.name;
      })
      .sort();

    if (travelTypes.join("-") !== cTravelTypes.join("-")) {
      _package.isValid = false;
      break;
    }
  }

  return _package;
}

export function markInvalidTrip(_trip) {
  _trip.isValid = true;

  if (new Date(_trip.startDate).getTime() < new Date().getTime()) {
    _trip.isValid = false;
    return _trip;
  }

  const travelCostCenters = _trip.costCenters.filter(
    (costCenter) => costCenter.costType === "TravelType"
  );
  let travelTypes = _trip.travelTypes.sort();
  if (travelTypes.length !== travelCostCenters.length) {
    _trip.isValid = false;
    return _trip;
  }

  const cTravelTypes = travelCostCenters
    .map((costCenter) => {
      return costCenter.travelType.name;
    })
    .sort();

  if (travelTypes.join("-") !== cTravelTypes.join("-")) {
    _trip.isValid = false;
    return _trip;
  }

  return _trip;
}
