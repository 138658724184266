import { costCenterConstants } from "../_constants";
import { costCentersService } from "../_services";
import { alertActions } from "./";

export const costCenterActions = {
  add,
  list,
  getById,
  update,
  deleteById,
};

function add(newCostCenter) {
  return (dispatch) => {
    return costCentersService.add(newCostCenter).then(
      (addedCostCenter) => {
        dispatch(success(addedCostCenter));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function success(obj) {
    return { type: costCenterConstants.NEW_COSTCENTER_SUCCESS, obj };
  }

  function failure(obj) {
    return { type: costCenterConstants.NEW_COSTCENTER_FAILURE, obj };
  }
}

function list(tripId) {
  return (dispatch) => {
    return costCentersService.list(tripId).then(
      (costCenters) => dispatch(success(costCenters)),
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  // function request(costCenters) {
  //   return { type: costCenterConstants.COSTCENTER_LIST_REQUEST, costCenters };
  // }

  function success(costCenters) {
    return { type: costCenterConstants.COSTCENTER_LIST_SUCCESS, costCenters };
  }

  function failure(costCenters) {
    return { type: costCenterConstants.COSTCENTER_LIST_FAILURE, costCenters };
  }
}

function getById(id) {
  return (dispatch) => {
    return costCentersService.getById(id).then(
      (costCenter) => dispatch(success(costCenter)),
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function success(costCenter) {
    return { type: costCenterConstants.GET_COSTCENTER_SUCCESS, costCenter };
  }

  function failure(costCenter) {
    return { type: costCenterConstants.GET_COSTCENTER_FAILURE, costCenter };
  }
}

function update(updateCostCenter) {
  return (dispatch) => {
    return costCentersService.update(updateCostCenter).then(
      (updateCostCenter) => dispatch(success(updateCostCenter)),
      (error) => dispatch(failure(updateCostCenter, error.toString()))
    );
  };

  // function request(updateCostCenter) {
  //   return {
  //     type: costCenterConstants.COSTCENTER_UPDATE_REQUEST,
  //     updateCostCenter,
  //   };
  // }
  function success(obj) {
    return {
      type: costCenterConstants.COSTCENTER_UPDATE_SUCCESS,
      updateCostCenter,
    };
  }
  function failure(obj) {
    return {
      type: costCenterConstants.COSTCENTER_UPDATE_FAILURE,
      updateCostCenter,
    };
  }
}

function deleteById(costCenterId) {
  return (dispatch) => {
    return costCentersService.deleteById(costCenterId).then(
      (deleteCostCenter) => {},
      (error) => dispatch(failure(costCenterId, error.toString()))
    );
  };

  function failure(obj) {
    return {
      type: costCenterConstants.COSTCENTER_UPDATE_FAILURE,
      obj,
    };
  }
}
