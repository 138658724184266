import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './app/App';
import * as serviceWorker from './serviceWorker';

import { Provider } from 'react-redux';
import { store } from './_helpers';


// eslint-disable-next-line no-extend-native
String.prototype.capitalize = function(allWords) {
  return (allWords) ? // If all words
     this.split(' ').map(word => word.capitalize()).join(' ') : // Break down the phrase to words and then recursive
                                                                // calls until capitalizing all words
     this.charAt(0).toUpperCase() + this.slice(1); // If allWords is undefined, capitalize only the first word,
                                            // meaning the first character of the whole string
}

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter basename="">
      <App />
    </BrowserRouter>
  </Provider>
  , document.getElementById('root')
);

serviceWorker.unregister();