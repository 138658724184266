import React from "react";
import { Route, Redirect } from "react-router-dom";

export const PrivateRoute = ({ component: Component, role = "", ...rest }) => {
  var roleMap = {
    admin: ["admin"],
    sales: ["admin", "sales", "blogger"],
    trekker: ["admin", "trekker"],
    vendor:["vendor", "sales"],
    blogger:["admin", "blogger"]
  };
  let enable = true;
  let user = localStorage.getItem("user");
  if (!user) {
    enable = false;
  } else {
    if (role && roleMap[role]) {
      try {
        user = JSON.parse(user);
        enable = roleMap[role].indexOf(user.role) !== -1;
      } catch (e) {
        enable = false;
      }
    }
    if (!enable) localStorage.removeItem("user");
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        enable ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/user-pages/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};
