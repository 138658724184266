
const initialState = {
    destinations: [],
    couponCodes: [],
    durations: [],
    eventLevels: [],
    packageTypes: [],
    urlTypes: [],
    travelTypes: [],
    stayTypes: [],
    trekLeaders: []
};

export function shared(state = initialState, action) {
    switch (action.type) {
        case "SHARED_LIST_SUCCESS":
            let _data = action.data || state;
            return _data;
        default:
            return { ...state }
    }
}