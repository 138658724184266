import React from "react";
import { Modal } from "react-bootstrap";
export const ExpenseModal = ({ ExpenseList, show, setShow }) => {
  const closeModal = () => {
    setShow(!show);
    };
  return (
    <Modal show={show} onHide={closeModal} size="md">
      <Modal.Header closeButton>
        <Modal.Title>Total Expense Chart</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Vendors</th>
              <th className="text-center">Total Traveller Expense</th>
            </tr>
          </thead>
          <tbody>
            {ExpenseList.length > 0
              ? ExpenseList.map((itx, index) => (
                  <tr key={index}>
                    <td>{itx.vendor_name}</td>
                    <td className="text-center">{itx.expense}</td>
                  </tr>
                ))
              : null}
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-sm btn-danger"
          onClick={closeModal}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};
