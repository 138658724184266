import React, { useState, useEffect } from "react";
import { Modal, Form } from "react-bootstrap";
import { bookingsService } from "../../_services/bookings.service";
import Select from "react-select";
const VehicleModal = ({
  show,
  setShow,
  accIndex,
  setAccIndex,
  setUpdate,
  vendorList,
  Id,
}) => {
  const [vehiclebooking, setVehicleBooking] = useState({
    vehicleDetails: {
      traveller_name: "",
      owner_name: "",
      vendor_name: "",
      vehicle_type: "",
      vehicle_No: "",
      amount: "",
      driver_name: "",
      vehicle_condition: "",
      driver_No: "",
      Notes: "",
    },
    coTravellers: [],
  });
  useEffect(() => {
    if (Id) {
      getBookingDetails();
    } else {
      closeModal();
    }
  }, [Id]);

  const getBookingDetails = () => {
    bookingsService.getById(Id).then((resp) => {
      if (!resp.coTravellers) {
        resp["coTravellers"] = Array.from(new Array(resp?.noOfUsers - 1)).map(
          (e) => {
            return {};
          }
        );
      }
      setVehicleBooking(resp);
    });
  };

  const handleCustomerVehicleDetailsChange = (key, info) => {
    let vehicleDetails = { ...vehiclebooking.vehicleDetails };
    if (key == "vendor_name") {
      vehicleDetails[key] = info.value;
    } else {
      vehicleDetails[key] = info;
    }
    setVehicleBooking({ ...vehiclebooking, vehicleDetails });
  };
  const handleCoTravellerData = (key, info, index) => {
    let Obj = Object.assign({}, vehiclebooking);
    if (Number.isInteger(index)) {
      if (key == "vendor_name") {
        Obj["coTravellers"][index].vehicleDetails = {
          ...Obj["coTravellers"][index].vehicleDetails,
          [key]: info.value
        };
      } else {
        Obj["coTravellers"][index].vehicleDetails = {
          ...Obj["coTravellers"][index].vehicleDetails,
          [key]: info,
        };
      }
    }
    setVehicleBooking(Obj);
  };
  const handleModalChanges = () => {
    const Obj = {
      vehicleDetails: vehiclebooking.vehicleDetails,
      coTravellers: vehiclebooking.coTravellers,
      id: vehiclebooking.id,
    };
    bookingsService.update(Obj).then((resp) => {
      setUpdate(false);
      closeModal();
    });
  };
  const closeModal = () => {
    setShow({ ...show, showVehicleModal: false });
  };
  const options = vendorList.map((vendor) => ({
    label: vendor.name || vendor.username,
    value: vendor.name || vendor.username,
  }));

  const renderCoTravellers = () => {
    return vehiclebooking.coTravellers.map((e, i) => (
      <div className="accordion mb-1" id={`accordion${i}`} key={`cot-${i}`}>
        <div className="card">
          <div className="card-header" id={`heading${i}`}>
            <h2 className="mb-0">
              <button
                className="btn btn-link btn-block text-left text-capitalize"
                type="button"
                data-toggle="collapse"
                data-target={`#collapse${i}`}
                aria-expanded="true"
                aria-controls={`collapse${i}`}
                onClick={() =>
                  accIndex.accIndexVehicle == i
                    ? setAccIndex({ ...accIndex, accIndexVehicle: -1 })
                    : setAccIndex({ ...accIndex, accIndexVehicle: i })
                }
              >
                {e.name || `Co-Traveller ${i + 1}`}{" "}
                {e.gender ? `(${e.gender})` : ""}
              </button>
            </h2>
          </div>

          <div
            id={`collapse${i}`}
            className={`collapse p-3 ${
              accIndex.accIndexVehicle == i ? "show" : ""
            }`}
            aria-labelledby={`heading${i}`}
            data-parent={`#accordion${i}`}
          >
            <div className="form-group">
              <label>Traveller Name</label>
              <input
                className="form-control"
                placeholder="traveller name"
                value={e.vehicleDetails?.traveller_name || ""}
                onChange={(event) =>
                  handleCoTravellerData("traveller_name", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>Owner Name</label>
              <input
                className="form-control"
                placeholder="owner name"
                value={e.vehicleDetails?.owner_name || ""}
                onChange={(event) =>
                  handleCoTravellerData("owner_name", event.target.value, i)
                }
              />
            </div>
            <div className="form-group react-select-custom">
              <label>Vendor</label>
              <Select
                placeholder="Vendor Name"
                noOptionsMessage={() => "No Vendors Found"}
                value={options.find((itx) => {
                  return itx.value === e.vehicleDetails?.vendor_name;
                })}
                onChange={(event) => {
                  handleCoTravellerData("vendor_name", event, i);
                }}
                options={options}
              />
            </div>
            <div className="form-group">
              <label>Vehicle Type</label>
              <select
                className="form-control"
                value={e.vehicleDetails?.vehicle_type}
                onChange={(event) =>
                  handleCoTravellerData("vehicle_type", event.target.value, i)
                }
              >
                <option value="Choose Vehicle Type">Choose Vehicle Type</option>
                <option value="Dzire">Dzire</option>
                <option value="Innova">Innova</option>
                <option value="Travera">Travera</option>
                <option value="Cruiser">Cruiser</option>
                <option value="Etios">Etios</option>
                <option value="Tempo(12)">Tempo(12)</option>
                <option value="Tempo(17)">Tempo(17)</option>
                <option value="Tempo(22)">Tempo(22)</option>
                <option value="Bus(25)">Bus(25)</option>
                <option value="Bus(33)">Bus(33)</option>
                <option value="Bus(50)">Bus(50)</option>
              </select>
            </div>
            <div className="form-group">
              <label>Vehicle Condition</label>
              <select
                className="form-control"
                value={e.vehicleDetails?.vehicle_condition}
                onChange={(event) =>
                  handleCoTravellerData(
                    "vehicle_condition",
                    event.target.value,
                    i
                  )
                }
              >
                <option value="Choose Vehicle Condition">
                  Choose Vehicle Condition
                </option>
                <option value="AC">AC</option>
                <option value="NON AC">NON AC</option>
              </select>
            </div>
            <div className="form-group">
              <label>Vehicle Number</label>
              <input
                className="form-control"
                placeholder="Vehicle Number"
                value={e.vehicleDetails?.vehicle_No || ""}
                onChange={(event) =>
                  handleCoTravellerData("vehicle_No", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>Amount</label>
              <input
                className="form-control"
                placeholder="Amount"
                value={e.vehicleDetails?.amount || ""}
                onChange={(event) =>
                  handleCoTravellerData("amount", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>Driver Name</label>
              <input
                className="form-control"
                placeholder="Driver Name"
                value={e.vehicleDetails?.driver_name || ""}
                onChange={(event) =>
                  handleCoTravellerData("driver_name", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>Driver Number</label>
              <input
                className="form-control"
                placeholder="Driver number"
                value={e.vehicleDetails?.driver_No || ""}
                onChange={(event) =>
                  handleCoTravellerData("driver_No", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>Notes</label>
              <textarea
                className="form-control"
                rows={6}
                value={e.vehicleDetails?.Notes}
                onChange={(event) =>
                  handleCoTravellerData("Notes", event.target.value, i)
                }
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    ));
  };
  return (
    <Modal show={show.showVehicleModal} onHide={() => closeModal()} size="md">
      <Modal.Header closeButton>
        <Modal.Title>Update Vehicle Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="row">
            <div className="col">
              <div className="form-group">
                <label>Traveller Name</label>
                <input
                  className="form-control"
                  placeholder="traveller name"
                  value={vehiclebooking.vehicleDetails?.traveller_name}
                  onChange={(event) =>
                    handleCustomerVehicleDetailsChange(
                      "traveller_name",
                      event.target.value
                    )
                  }
                />
              </div>
              <div className="form-group">
                <label>Owner Name</label>
                <input
                  className="form-control"
                  placeholder="owner name"
                  value={vehiclebooking.vehicleDetails?.owner_name}
                  onChange={(event) =>
                    handleCustomerVehicleDetailsChange(
                      "owner_name",
                      event.target.value
                    )
                  }
                />
              </div>
              <div className="form-group react-select-custom">
                <label>Vendor</label>
                <Select
                  placeholder="Vendor Name"
                  noOptionsMessage={() => "No Vendors Found"}
                  value={options.find((itx) => {
                    return (
                      itx.value === vehiclebooking.vehicleDetails?.vendor_name
                    );
                  })}
                  onChange={(event) => {
                    handleCustomerVehicleDetailsChange("vendor_name", event);
                  }}
                  options={options}
                />
              </div>
              <div className="form-group">
                <label>Vehicle Type</label>
                <select
                  className="form-control"
                  value={vehiclebooking.vehicleDetails?.vehicle_type}
                  onChange={(event) =>
                    handleCustomerVehicleDetailsChange(
                      "vehicle_type",
                      event.target.value
                    )
                  }
                >
                  <option value="Choose Vehicle Type">
                    Choose Vehicle Type
                  </option>
                  <option value="Dzire">Dzire</option>
                  <option value="Innova">Innova</option>
                  <option value="Travera">Travera</option>
                  <option value="Cruiser">Cruiser</option>
                  <option value="Etios">Etios</option>
                  <option value="Tempo(12)">Tempo(12)</option>
                  <option value="Tempo(17)">Tempo(17)</option>
                  <option value="Tempo(22)">Tempo(22)</option>
                  <option value="Bus(25)">Bus(25)</option>
                  <option value="Bus(33)">Bus(33)</option>
                  <option value="Bus(50)">Bus(50)</option>
                </select>
              </div>
              <div className="form-group">
                <label>Vehicle Condition</label>
                <select
                  className="form-control"
                  value={vehiclebooking.vehicleDetails?.vehicle_condition}
                  onChange={(event) =>
                    handleCustomerVehicleDetailsChange(
                      "vehicle_condition",
                      event.target.value
                    )
                  }
                >
                  <option value="Choose Vehicle Condition">
                    Choose Vehicle Condition
                  </option>
                  <option value="AC">AC</option>
                  <option value="NON AC">NON AC</option>
                </select>
              </div>
              <div className="form-group">
                <label>Vehicle Number</label>
                <input
                  className="form-control"
                  placeholder="Vehicle Number"
                  value={vehiclebooking.vehicleDetails?.vehicle_No}
                  onChange={(event) =>
                    handleCustomerVehicleDetailsChange(
                      "vehicle_No",
                      event.target.value
                    )
                  }
                />
              </div>
              <div className="form-group">
                <label>Amount</label>
                <input
                  className="form-control"
                  placeholder="Amount"
                  value={vehiclebooking.vehicleDetails?.amount}
                  onChange={(event) =>
                    handleCustomerVehicleDetailsChange(
                      "amount",
                      event.target.value
                    )
                  }
                />
              </div>
              <div className="form-group">
                <label>Driver Name</label>
                <input
                  className="form-control"
                  placeholder="Driver Name"
                  value={vehiclebooking.vehicleDetails?.driver_name}
                  onChange={(event) =>
                    handleCustomerVehicleDetailsChange(
                      "driver_name",
                      event.target.value
                    )
                  }
                />
              </div>
              <div className="form-group">
                <label>Driver Number</label>
                <input
                  className="form-control"
                  placeholder="Driver number"
                  value={vehiclebooking.vehicleDetails?.driver_No}
                  onChange={(event) =>
                    handleCustomerVehicleDetailsChange(
                      "driver_No",
                      event.target.value
                    )
                  }
                />
              </div>
              <div className="form-group">
                <label>Notes</label>
                <textarea
                  className="form-control"
                  rows={6}
                  value={vehiclebooking.vehicleDetails?.Notes}
                  onChange={(event) =>
                    handleCustomerVehicleDetailsChange(
                      "Notes",
                      event.target.value
                    )
                  }
                ></textarea>
              </div>
            </div>
          </div>
          {vehiclebooking.coTravellers.length > 0 && (
            <div>{renderCoTravellers()}</div>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-sm btn-danger"
          onClick={() => closeModal()}
        >
          Close
        </button>
        <button className="btn btn-sm btn-primary" onClick={handleModalChanges}>
          Save Changes
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default VehicleModal;
