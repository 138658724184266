import config from "./config.json";
import { authHeader } from "../_helpers";
import { userActions } from "../_actions";
import axios from "axios";

config.apiUrl = process.env.REACT_APP_API_URL

export const tripsService = {
  add,
  list,
  update,
  getById,
  deleteById,
  replicate,
  filter
};

async function add(newTrip) {
  let user = JSON.parse(localStorage.getItem("user"));
  const response = await axios.post(`${config.apiUrl}/trips`, newTrip, {
    headers: {
      "Content-Type": "application/json",
      Authorization: user.id,
    },
  });
  handleResponse(response);
}

function list(packageId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${config.apiUrl}/trips?filter=${JSON.stringify({
      where: { packageId },
      include: ["trekLeader", "package", { costCenters: "travelType" }],
    })}`,
    requestOptions
  ).then(handleResponse);
}

function filter(where) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${config.apiUrl}/trips?filter=${JSON.stringify({
      where,
      include: [
        {
          relation: "trekLeader"
        },
        {
          relation: "package"
        },
        {
          relation: 'costCenters',
          scope: ["travelType"]
        },
        {
          relation: 'bookings',
          scope: {
            where: {
              status: 'SUCCESS'
            }
          }
        }
      ],
      order: 'endDate ASC'
    })}`,
    requestOptions
  ).then(handleResponse);
}

function getById(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${config.apiUrl}/trips/${id}?filter=${JSON.stringify({
      include: ["trekLeader", "package"],
    })}`,
    requestOptions
  ).then(handleResponse);
}

async function update(updateTrip) {
  let user = JSON.parse(localStorage.getItem("user"));
  const response = await axios.patch(`${config.apiUrl}/trips/${updateTrip.id}`, updateTrip, {
    headers: {
      "Content-Type": "application/json",
      Authorization: user.id,
    },
  });
  handleResponse(response);
}

function deleteById(id) {
  const requestOptions = {
    method: "DELETE",
    headers: { ...authHeader() },
  };

  return fetch(`${config.apiUrl}/trips/${id}`, requestOptions).then(
    handleResponse
  );
}

async function replicate(tripId) {
  let user = JSON.parse(localStorage.getItem("user"));
  await axios.post(`${config.apiUrl}/trips/replicate`, { tripId }, {
    headers: {
      "Content-Type": "application/json",
      Authorization: user.id,
    },
  });
} 

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        userActions.logout();
        // location.reload(true);
      }

      const error = (data && data.error) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
