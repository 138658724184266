import config from "./config.json";
import { authHeader } from "../_helpers";
import { userActions } from "../_actions";

config.apiUrl = process.env.REACT_APP_API_URL

export const sharedService = {
  getAll,
  getPostUrlTypes,
};
async function getAll() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const sharedData = {
    destinations: [],
    couponCodes: [],
    durations: [],
    eventLevels: [],
    packageTypes: [],
    urlTypes: [],
    travelTypes: [],
    stayTypes: [],
    trekLeaders: [],
  };

  try {
    sharedData.destinations = await fetch(
      `${config.apiUrl}/destinations`,
      requestOptions
    ).then(handleResponse);
    sharedData.couponCodes = await fetch(
      `${config.apiUrl}/coupon-codes`,
      requestOptions
    ).then(handleResponse);
    sharedData.durations = await fetch(
      `${config.apiUrl}/durations`,
      requestOptions
    ).then(handleResponse);
    sharedData.eventLevels = await fetch(
      `${config.apiUrl}/event-levels`,
      requestOptions
    ).then(handleResponse);
    sharedData.packageTypes = await fetch(
      `${config.apiUrl}/package-types`,
      requestOptions
    ).then(handleResponse);
    sharedData.urlTypes = await fetch(
      `${config.apiUrl}/url-types`,
      requestOptions
    ).then(handleResponse);
    sharedData.travelTypes = await fetch(
      `${config.apiUrl}/travel-types`,
      requestOptions
    ).then(handleResponse);
    sharedData.stayTypes = await fetch(
      `${config.apiUrl}/stay-types`,
      requestOptions
    ).then(handleResponse);
    sharedData.trekLeaders = await fetch(
      `${config.apiUrl}/users-by-role/trekker`,
      requestOptions
    ).then(handleResponse);
    return sharedData;
  } catch (e) {
    return Promise.reject(e);
  }
}

async function getPostUrlTypes() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const sharedData = {
    postUrlTypes: [],
  };

  try {
    sharedData.postUrlTypes = await fetch(
      `${config.apiUrl}/post-url-types`,
      requestOptions
    ).then(handleResponse);
    return sharedData;
  } catch (e) {
    return Promise.reject(e);
  }
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        userActions.logout();
        // location.reload(true);
      }

      const error = (data && data.error) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
