import React, { Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import Spinner from "../app/shared/Spinner";
import { PrivateRoute } from "../_components/PrivateRoute";
import Config from "./config/Config";
import CouponCodes from "./coupon-codes/CouponCodes";
import OnGoingTrips from "./OnGoingTrips/OnGoingTrips";
import TripReports from "./TripReports/TripReports";
import VendorReport from "./Vendor/vendorReport";

const Dashboard = lazy(() => import("./dashboard/Dashboard"));
const Packages = lazy(() => import("./packages/Packages"));
const Trips = lazy(() => import("./trips/Trips"));
const CostCenters = lazy(() => import("./costcenters/CostCenters"));
const Bookings = lazy(() => import("./bookings/Bookings"));
const Blog = lazy(() => import("./blog/Blog"));
const Users = lazy(() => import("./users/Users"));
const Sales = lazy(() => import("./sales/Sales"));

const Buttons = lazy(() => import("./basic-ui/Buttons"));
const Dropdowns = lazy(() => import("./basic-ui/Dropdowns"));

const BasicElements = lazy(() => import("./form-elements/BasicElements"));

const BasicTable = lazy(() => import("./tables/BasicTable"));

const Mdi = lazy(() => import("./icons/Mdi"));

const ChartJs = lazy(() => import("./charts/ChartJs"));

const Error404 = lazy(() => import("./error-pages/Error404"));
const Error500 = lazy(() => import("./error-pages/Error500"));

const Login = lazy(() => import("./user-pages/Login"));

export default function AppRoutes() {
  const auth = useSelector((state) => state.authentication);
  return (
    <Suspense fallback={<Spinner />}>
      <Switch>
        <PrivateRoute exact path="/dashboard" component={Dashboard} role="sales"/>
        <PrivateRoute exact path="/ongoing_trips" component={OnGoingTrips} />
        <PrivateRoute exact path="/trip_report" component={TripReports} role="sales" />
        <PrivateRoute exact path="/vendor_report" component={VendorReport} role="vendor"/>
        <PrivateRoute exact path="/packages" component={Packages} />
        <PrivateRoute exact path="/trips/:packageId" component={Trips} />
        <PrivateRoute
          exact
          path="/cost-centers/:tripId"
          component={CostCenters}
        />
        <PrivateRoute exact path="/blog" component={Blog} />
        <PrivateRoute
          exact
          path="/bookings"
          component={Bookings}
          role="sales"
        />
        <PrivateRoute exact path="/coupon-codes" component={CouponCodes} />
        <PrivateRoute exact path="/_config_" component={Config} />
        <PrivateRoute exact path="/sales" component={Sales} role="sales" />
        <PrivateRoute exact path="/users" component={Users} role="admin" />

        <Route path="/basic-ui/buttons" component={Buttons} />
        <Route path="/basic-ui/dropdowns" component={Dropdowns} />

        <Route path="/form-Elements/basic-elements" component={BasicElements} />

        <Route path="/tables/basic-table" component={BasicTable} />

        <Route path="/icons/mdi" component={Mdi} />

        <Route path="/charts/chart-js" component={ChartJs} />

        <Route path="/user-pages/login" component={Login} />

        <Route path="/error-pages/error-404" component={Error404} />
        <Route path="/error-pages/error-500" component={Error500} />

        { auth.user?.role === "admin" ? 
            <Redirect to="/dashboard" /> : 
          auth.user?.role === "blogger" ? 
            <Redirect to="/blog" /> :  
          auth.user?.role === "sales" ? 
            <Redirect to="/trip_report" /> : 
          <Redirect to="/vendor_report" />}
      </Switch>
    </Suspense>
  );
}
