import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Modal, Tabs, Tab } from "react-bootstrap";
import { useEffect } from "react";
import axios from "axios";
import config from "../../_services/config.json";
const NotificationModal = ({ data, show, setModal, fetchActive }) => {
  const [ActiveKey, setActiveKey] = useState("active");
  const [markedNotification, setmarkedNotification] = useState({});
  const authData = useSelector((state) => state.authentication);
  let ActiveNotifications = data.filter((item) => {
    let isMatchingEmail = item.email === authData.user.email;
    const hasIncompleteNotification = item.userNotifications.some(
      (notification) => notification.isCompleted === false
    );
    return isMatchingEmail && hasIncompleteNotification;
  });
  console.log("Active Notifications is", ActiveNotifications);
  let completedNotifications = data.filter((item) => {
    let isMatchingEmail = item.email === authData.user.email;
    const hasIncompleteNotification = item.userNotifications.some(
      (notification) => notification.isCompleted === true
    );
    return isMatchingEmail && hasIncompleteNotification;
  });

  useEffect(() => {
    if (authData?.user.role === "admin" || authData?.user.role === "sales") {
      fetchActive(ActiveNotifications);
    }
  }, [fetchActive]);
  const closeModal = () => {
    setModal(false);
  };
  const handleCompleted = (markednotify, index) => {
    let Obj = Object.assign({}, markednotify);
    Obj["userNotifications"][index] = {
      ...Obj["userNotifications"][index],
      ["isCompleted"]: true,
    };
    setmarkedNotification(Obj);
  };
  useEffect(() => {
    const updateData = () => {
      axios({
        method: "POST",
        url: `${config.apiUrl}/users/update-notifications`,
        data: { markedNotification },
      })
        .then((res) => {
          console.log("hit");
        })
        .catch((err) => {
          console.log(err);
        });
    };
    updateData();
  }, [markedNotification]);
  return (
    <Modal show={show} onHide={closeModal} className="custom-modal">
      <Modal.Header closeButton>
        <Modal.Title>Notification</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs activeKey={ActiveKey} onSelect={(k) => setActiveKey(k)}>
          <Tab eventKey="active" title="Active">
            {authData.user?.role === "admin" || authData.user?.role === "sales" ? (
              ActiveNotifications.map((item) => (
                item.userNotifications.map((element, index) => (
                  <div className="card shadow mb-2 mt-3 p-0" key={index}>
                    <div className="card-body custom-body">
                      <p className="card-text font-weight-bold">
                        {element.message}
                      </p>
                      <a
                        className="anchor"
                        onClick={() => handleCompleted(item, index)}
                      >
                        Mark as Completed
                      </a>
                    </div>
                  </div>
                ))))) : null}
            {ActiveNotifications.length === 0 ? (
              <h6 className="text-center mt-3 mb-2 font-weight-bold">
                <b>No Active Notifications Found</b>
              </h6>
            ) : null}
          </Tab>
          <Tab eventKey="completed" title="Completed">
            {completedNotifications.map((item) =>
              item.userNotifications.map((element, idx) => (
                <div class="card shadow mb-2 mt-3 p-0" key={idx}>
                  <div class="card-body custom-body">
                    <p class="card-text font-weight-bold">{element.message}</p>
                  </div>
                </div>
              ))
            )}
            {completedNotifications.length === 0 ? (
              <h6 className="text-center mt-3">
                <b>No Completed Notifications Found</b>
              </h6>
            ) : null}
          </Tab>
        </Tabs>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-sm btn-danger" onClick={closeModal}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default NotificationModal;
