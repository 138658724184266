import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";

function Sidebar(props) {
  const [state, setState] = useState([]);
  const authData = useSelector((state) => state.authentication);

  function onRouteChanged() {
    document.querySelector("#sidebar").classList.remove("active");
    Object.keys(state).forEach((i) => {
      setState({ [i]: false });
    });

    const dropdownPaths = [
      { path: "/apps", state: "appsMenuOpen" },
      { path: "/basic-ui", state: "basicUiMenuOpen" },
      { path: "/form-elements", state: "formElementsMenuOpen" },
      { path: "/tables", state: "tablesMenuOpen" },
      { path: "/icons", state: "iconsMenuOpen" },
      { path: "/charts", state: "chartsMenuOpen" },
      { path: "/user-pages", state: "userPagesMenuOpen" },
      { path: "/error-pages", state: "errorPagesMenuOpen" },
    ];

    dropdownPaths.forEach((obj) => {
      if (isPathActive(obj.path)) {
        setState({ [obj.state]: true });
      }
    });
  }

  function isPathActive(path) {
    return props.location.pathname.startsWith(path);
  }

  useEffect(() => {
    onRouteChanged();
    // add className 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  });

  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
      <div className="text-center sidebar-brand-wrapper d-flex align-items-center">
        <a className="sidebar-brand brand-logo" href="index.html">
          <img
            src={require("../../assets/images/goadventure-log.png")}
            alt="logo"
          />
        </a>
        <a className="sidebar-brand brand-logo-mini pt-3" href="index.html">
          <img
            src={require("../../assets/images/goadventure-log.png")}
            alt="logo"
          />
        </a>
      </div>

      <ul className="nav">
        <li className="nav-item nav-profile not-navigation-link">
          <div className="nav-link">
            <Dropdown>
              <Dropdown.Toggle className="nav-link user-switch-dropdown-toggler p-0 toggle-arrow-hide bg-transparent border-0 w-100">
                <div className="d-flex justify-content-between align-items-start">
                  <div className="profile-image">
                    <img
                      className="img-xs rounded-circle"
                      src={require("../../assets/images/faces-clipart/pic-4.png")}
                      alt="profile"
                    />
                    <div className="dot-indicator bg-success"></div>
                  </div>
                  <div className="text-wrapper">
                    <p className="profile-name">
                      {authData && authData.user ? authData.user.username : ""}
                    </p>
                    <p className="designation">
                      {" "}
                      {authData && authData.user ? authData.user.role : ""} User
                    </p>
                  </div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="preview-list navbar-dropdown">
                <Dropdown.Item
                  className="dropdown-item p-0 preview-item d-flex align-items-center"
                  href="!#"
                  onClick={(evt) => evt.preventDefault()}
                >
                  <div className="d-flex">
                    <div className="py-3 px-4 d-flex align-items-center justify-content-center">
                      <i className="mdi mdi-bookmark-plus-outline mr-0"></i>
                    </div>
                    <div className="py-3 px-4 d-flex align-items-center justify-content-center border-left border-right">
                      <i className="mdi mdi-account-outline mr-0"></i>
                    </div>
                    <div className="py-3 px-4 d-flex align-items-center justify-content-center">
                      <i className="mdi mdi-alarm-check mr-0"></i>
                    </div>
                  </div>
                </Dropdown.Item>
                <Dropdown.Item
                  className="dropdown-item preview-item d-flex align-items-center text-small"
                  onClick={(evt) => evt.preventDefault()}
                >
                  Manage Accounts
                </Dropdown.Item>
                <Dropdown.Item
                  className="dropdown-item preview-item d-flex align-items-center text-small"
                  onClick={(evt) => evt.preventDefault()}
                >
                  Change Password
                </Dropdown.Item>
                <Dropdown.Item
                  className="dropdown-item preview-item d-flex align-items-center text-small"
                  onClick={(evt) => evt.preventDefault()}
                >
                  Check Inbox
                </Dropdown.Item>
                <Dropdown.Item
                  className="dropdown-item preview-item d-flex align-items-center text-small"
                  onClick={(evt) => evt.preventDefault()}
                >
                  Sign Out
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </li>

        {authData.user && authData.user.role === "admin" ? (
          <li
            className={
              isPathActive("/dashboard") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/dashboard">
              <i className="mdi mdi-television menu-icon"></i>
              <span className="menu-title">Dashboard</span>
            </Link>
          </li>
        ) : null}

        {authData.user &&
        ["admin", "sales"].indexOf(authData.user.role) > -1 ? (
          <li
            className={
              isPathActive("/_config_") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/trip_report">
              <i className="mdi mdi-television menu-icon"></i>
              <span className="menu-title">Trip Report</span>
            </Link>
          </li>
        ) : null}

        {authData.user && ["vendor"].indexOf(authData.user.role) > -1 ? (
          <li
            className={
              isPathActive("/vendor_report") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/vendor_report">
              <i className="mdi mdi-television menu-icon"></i>
              <span className="menu-title">Vendor Report</span>
            </Link>
          </li>
        ) : null}

        {authData.user && ["vendor"].indexOf(authData.user.role) > -1 ? (
          <li
            className={
              isPathActive("/vendor_billing") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/vendor_report">
              <i className="mdi mdi-television menu-icon"></i>
              <span className="menu-title">Vendor Billing</span>
            </Link>
          </li>
        ) : null}

        {authData.user &&
        ["admin", "sales"].indexOf(authData.user.role) > -1 ? (
          <li
            className={
              isPathActive("/_config_") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/ongoing_trips">
              <i className="mdi mdi-television menu-icon"></i>
              <span className="menu-title">OnGoing Trips</span>
            </Link>
          </li>
        ) : null}

        {/* Main Start */}
        {authData.user && authData.user.role === "admin" ? (
          <li
            className={
              isPathActive("/packages") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/packages">
              <i className="mdi mdi-television menu-icon"></i>
              <span className="menu-title">Packages</span>
            </Link>
          </li>
        ) : null}

        {authData.user &&
          (authData.user.role === "admin" ||
            authData.user.role === "blogger") && (
            <li
              className={isPathActive("/blog") ? "nav-item active" : "nav-item"}
            >
              <Link className="nav-link" to="/blog">
                <i className="mdi mdi-television menu-icon"></i>
                <span className="menu-title">Blog</span>
              </Link>
            </li>
          )}

        {authData.user &&
          (authData.user.role === "admin" ||
            authData.user.role === "sales") && (
            <li
              className={
                isPathActive("/bookings") ? "nav-item active" : "nav-item"
              }
            >
              <Link className="nav-link" to="/bookings">
                <i className="mdi mdi-television menu-icon"></i>
                <span className="menu-title">Bookings</span>
              </Link>
            </li>
          )}
        {authData.user && authData.user.role === "admin" ? (
          <li
            className={
              isPathActive("/coupon-codes") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/coupon-codes">
              <i className="mdi mdi-television menu-icon"></i>
              <span className="menu-title">Coupon Codes</span>
            </Link>
          </li>
        ) : null}

        {authData.user &&
          (authData.user.role === "admin" ||
            authData.user.role === "sales") && (
            <li
              className={
                isPathActive("/sales") ? "nav-item active" : "nav-item"
              }
            >
              <Link className="nav-link" to="/sales">
                <i className="mdi mdi-television menu-icon"></i>
                <span className="menu-title">Sales Overview</span>
              </Link>
            </li>
          )}
        {authData.user && authData.user.role === "admin" && (
          <li
            className={
              isPathActive("/users") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/users">
              <i className="mdi mdi-television menu-icon"></i>
              <span className="menu-title">Users</span>
            </Link>
          </li>
        )}
        {authData.user && authData.user.role === "admin" ? (
          <li
            className={
              isPathActive("/_config_") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/_config_">
              <i className="mdi mdi-television menu-icon"></i>
              <span className="menu-title">Config</span>
            </Link>
          </li>
        ) : null}
      </ul>
    </nav>
  );
}

export default withRouter(Sidebar);
