import config from "./config.json";
import { authHeader } from "../_helpers";
import axios from "axios";

export const userService = {
  login,
  logout,
  add,
  getAll,
  getById,
  update,
  updateNotification,
  deleteById: _delete,
  getByRole,
  passwordReset,
  getCombinedSalesUsers,
};

config.apiUrl = process.env.REACT_APP_API_URL
function login(loginDetails) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(loginDetails),
  };

  return fetch(`${config.apiUrl}/users/login`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem("user", JSON.stringify(user));
      return user;
    });
}

export function logout() {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({}),
  };

  localStorage.removeItem("user");

  return fetch(`${config.apiUrl}/users/logout`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
    });
}

function getAll() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}/users`, requestOptions).then(handleResponse);
}

async function getCombinedSalesUsers() {
  return [...(await getByRole("sales")), ...(await getByRole("admin"))];
}

function getByRole(role, options) {
  options = options || {};
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${config.apiUrl}/users-by-role/${role}?filter=${JSON.stringify(options)}`,
    requestOptions
  ).then(handleResponse);
}

function getById(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}/users/${id}`, requestOptions).then(
    handleResponse
  );
}

async function add(userRequest) {
  let user = JSON.parse(localStorage.getItem("user"));
  await axios.post(`${config.apiUrl}/users/add`, userRequest, {
    headers: {
      "Content-Type": "application/json",
      Authorization: user.id,
    },
  });
}

function updateNotification(user) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(user),
  };

  return fetch(`${config.apiUrl}/users/update-notifications`, requestOptions).then(
    handleResponse
  );
}

function update(user) {
  const requestOptions = {
    method: "PATCH",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(user),
  };

  return fetch(`${config.apiUrl}/users/${user.id}`, requestOptions).then(
    handleResponse
  );
}

function passwordReset(user) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(user),
  };

  return fetch(`${config.apiUrl}/users/password-reset`, requestOptions).then(
    handleResponse
  );
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}/users/${id}`, requestOptions).then(
    handleResponse
  );
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        localStorage.removeItem("user");
        // location.reload(true);
      }

      const error = (data && data.error.toString()) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
