import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Alert } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Multiselect } from "multiselect-react-dropdown";

import DatePicker from "react-datepicker";

import { alertActions, packagesActions, sharedActions } from "../../_actions";

import config from "./../../_services/config.json";
import moment from "moment";

import { useForm } from "react-hook-form";
import { packagesService, sharedService } from "../../_services";
import { couponCodeService } from "../../_services/coupon-codes.service";
import CouponCodesTable from "./CouponCodesTable";
import { markInvalidPackages } from "../../_helpers/util";

const newObj = {
  code: "",
  description: "",
  type: "",
  value: 0,
  expirationDate: new Date().setMonth(new Date().getMonth() + 1),
  usageLimit: 0,
  usageCount: 0,
  packageIds: [],
};

function CouponCodes() {
  const [show, setShow] = useState(false);

  const [isUpdate, setIsUpdate] = useState(false);
  const [formError, setFormError] = useState("");

  const [newCouponCode, setNewCouponCode] = useState(Object.assign(newObj));
  const [couponCodes, setCouponCodes] = useState([]);
  const [iData, setIData] = useState({
    types: [{ name: "Percentage" }, { name: "Flat" }],
  });

  const {
    register,
    setError,
    clearErrors,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const tableColumns = [
    {
      Header: "Code",
      accessor: "code",
    },
    {
      Header: "Description",
      accessor: "description",
    },
    {
      Header: "Type",
      accessor: "type",
    },
    {
      Header: "Value",
      accessor: "value",
    },
    {
      Header: "Expiration Date",
      accessor: "expirationDate",
      Cell: ({ cell: { value } }) => {
        return <>{moment(value).format("ddd, MMM Do YYYY, h:mm:ss a")}</>;
      },
    },
    {
      Header: "Usage Limit",
      accessor: "usageLimit",
    },
    {
      Header: "Used Count",
      accessor: "usageCount",
    },
    {
      Header: "Actions",
      accessor: "",
      Cell: ({ cell: { value }, row: { original } }) => {
        return (
          <>
            <Button
              variant="warning"
              className="m5 action-btn"
              onClick={(e) => onEdit(original.id)}
            >
              Edit
            </Button>
            <Button
              variant="danger"
              className="m5 action-btn"
              onClick={(e) => onDelete(original.id)}
            >
              Delete
            </Button>
          </>
        );
      },
    },
  ];

  const handleClose = () => {
    setShow(false);
    setIsUpdate(false);
    setNewCouponCode(Object.assign(newObj));
  };
  const handleShow = () => {
    setNewCouponCode(Object.assign(newObj));
    reset();
    setShow(true);
    setFormError("");
  };

  function onEdit(couponCodeId) {
    setIsUpdate(true);
    setShow(true);
    reset();
    setFormError("");

    let couponCode = couponCodes.find((e) => e.id === couponCodeId);

    let updateV = {
      ...newObj,
      ...couponCode,
    };

    for (let fieldName in couponCode) {
      setValue(fieldName, couponCode[fieldName]);
    }

    updateV.packageIds = iData.packages.filter(
      (_package) => updateV.packageIds.indexOf(_package.id) !== -1
    );

    updateV.expirationDate = new Date(updateV.expirationDate);

    setNewCouponCode(updateV);
  }

  function onDelete(couponCodeId) {
    const _c = window.confirm("Are you sure you want to delete?");
    if (_c) {
      couponCodeService.deleteById(couponCodeId).then((e) => {
        init();
      });
    }
  }

  function onSubmit(data) {
    if (isUpdate) {
      data.id = newCouponCode.id;
      const couponCode = {
        ...newCouponCode,
        ...data,
        usageCount: 0,
        packageIds: newCouponCode.packageIds.map((e) => e.id),
      };
      couponCodeService
        .update(couponCode)
        .then(() => {
          handleClose();
          init();
        })
        .catch((err) => {
          if (err.code === 11000) {
            setFormError("Coupon code already exists");
          } else {
            setFormError("Error, please try again");
          }
        });
    } else {
      const couponCode = { ...newCouponCode, ...data, usageCount: 0 };
      couponCode.packageIds = couponCode.packageIds.map((e) => e.id);
      couponCodeService
        .add(couponCode)
        .then(() => {
          handleClose();
          init();
        })
        .catch((err) => {
          if (err.code === 11000) {
            setFormError("Coupon code already exists");
          } else {
            setFormError("Error, please try again");
          }
        });
    }
  }

  function onFieldChange({ field, value }) {
    setNewCouponCode({
      ...newCouponCode,
      [field]: value,
    });
    // for now only required check
    if (value) {
      clearErrors(field);
    }
  }

  async function getIData() {
    let packages = await packagesService.list();
    packages = packages.filter(
      (_package) => markInvalidPackages(_package).isValid
    );

    setIData({
      ...iData,
      packages: packages.map((_package) => {
        return { id: _package.id, name: _package.name };
      }),
    });
  }

  async function init() {
    reset();
    setIsUpdate(false);
    setNewCouponCode(Object.assign(newObj));
    couponCodeService.getAll().then((data) => {
      setCouponCodes(data);
    });
    setFormError("");
  }

  useEffect(() => {
    init();
    getIData();
    alertActions.clear();
  }, []);

  return (
    <div>
      <div className="row page-header">
        <div className="col-md-4">
          <h3 className="page-title">Coupon Codes</h3>
        </div>
        <div className="col-md-2 offset-md-6">
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleShow}
          >
            Add Coupon
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="table-responsive">
            <CouponCodesTable columns={tableColumns} data={couponCodes} />
          </div>
        </div>
      </div>

      <Modal
        dialogClassName="modal-90w"
        show={show}
        enforceFocus={false}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{isUpdate ? "Update Coupon" : "Add Coupon"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <form
                    autoComplete="off"
                    id="couponCodeForm"
                    className="forms-sample"
                    onReset={reset}
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <Form.Group className="row">
                      {/* <label htmlFor="name" className="col-sm-1 col-form-label">Name</label> */}
                      <div className="col-sm-2">
                        Code*
                        <Form.Control
                          autoComplete="off"
                          type="text"
                          className="form-control"
                          id="code"
                          placeholder="Coupon Code"
                          {...register("code", { required: true })}
                        />
                        {errors.code && (
                          <span className="text-danger">Required</span>
                        )}
                      </div>
                      <div className="col-sm-2">
                        Description*
                        <Form.Control
                          autoComplete="off"
                          type="text"
                          className="form-control"
                          id="description"
                          placeholder="Description"
                          {...register("description", { required: true })}
                        />
                        {errors.description && (
                          <span className="text-danger">Required</span>
                        )}
                      </div>
                      <div className="col-sm-2">
                        Type*
                        <select
                          className="form-control"
                          id="type"
                          {...register("type", { required: true })}
                        >
                          {iData.types.map((o, i) => {
                            return (
                              <option key={i} value={o.name}>
                                {o.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="col-sm-2">
                        Value*
                        <Form.Control
                          autoComplete="off"
                          type="number"
                          className="form-control"
                          id="value"
                          placeholder="value"
                          {...register("value", { required: true })}
                        />
                        {errors.value && (
                          <span className="text-danger">Required</span>
                        )}
                      </div>
                      <div className="col-sm-2">
                        Expiration Date *
                        <DatePicker
                          className="form-control w-100"
                          selected={newCouponCode.expirationDate}
                          onChange={(e) =>
                            onFieldChange({ field: "expirationDate", value: e })
                          }
                          timeInputLabel="Time:"
                          dateFormat="MM/dd/yyyy h:mm aa"
                          showTimeInput
                        />
                      </div>
                      <div className="col-sm-2">
                        Usage Limit*
                        <Form.Control
                          autoComplete="off"
                          type="number"
                          className="form-control"
                          id="usageLimit"
                          placeholder="Usage limit"
                          {...register("usageLimit", { required: true })}
                        />
                        {errors.usageLimit && (
                          <span className="text-danger">Required</span>
                        )}
                      </div>
                      <div className="col-sm-2">
                        Usage Count
                        <Form.Control
                          disabled
                          autoComplete="off"
                          type="number"
                          className="form-control"
                          id="usageCount"
                          placeholder="Usage Count"
                          value="0"
                          {...register("usageCount", { required: false })}
                        />
                        {errors.usageLimit && (
                          <span className="text-danger">Required</span>
                        )}
                      </div>
                      <div className="col-sm-4">
                        Packages
                        <Multiselect
                          selectedValues={newCouponCode.packageIds}
                          options={iData.packages}
                          displayValue={"name"}
                          onSelect={(e) => {
                            onFieldChange({ field: "packageIds", value: e });
                          }}
                          onRemove={(e) =>
                            onFieldChange({ field: "packageIds", value: e })
                          }
                        />
                      </div>
                    </Form.Group>
                  </form>
                  {formError && (
                    <span className="text-danger">{formError}</span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button form="couponCodeForm" type="submit" variant="primary">
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default CouponCodes;
