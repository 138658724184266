import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { userActions } from "../../_actions";
import config from "../../_services/config.json";
import axios from "axios";
import NotificationModal from "../Modals/NotificationModal";
import { useSelector } from "react-redux";

export default function Navbar() {
  const [notificationData, setNotificationData] = useState([]);
  const [count, setCount] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const auth = useSelector((state) => state.authentication);
  function toggleOffcanvas() {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${config.apiUrl}/users/fetch-reminders`
        );
        setNotificationData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    let intervalId = setInterval(fetchData, 30000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  function signOut() {
    userActions.logout();
    localStorage.removeItem("token");
    window.location.href = "/user-pages/login";
  }
  function opemModal() {
    setShowModal(true);
  }
  function getActiveNotifications(data) {
    setCount(data?.reduce((acc, item) => acc + item.userNotifications.length, 0));
  }
  useEffect(() => {
    getActiveNotifications();
  }, [notificationData]);
  return (
    <nav className="navbar col-lg-12 col-12 p-lg-0 fixed-top d-flex flex-row">
      <div className="navbar-menu-wrapper d-flex align-items-center justify-content-between">
        <a
          className="navbar-brand brand-logo-mini align-self-center d-lg-none"
          href="!#"
          onClick={(evt) => evt.preventDefault()}
        >
          <img src={require("../../assets/images/logo-mini.svg")} alt="logo" />
        </a>
        <button
          className="navbar-toggler navbar-toggler align-self-center"
          type="button"
          onClick={() => document.body.classList.toggle("sidebar-icon-only")}
        >
          <i className="mdi mdi-menu"></i>
        </button>
        <ul className="navbar-nav navbar-nav-left header-links align-self-center">
          <li className="nav-item font-weight-semibold d-none  d-md-flex">
            Help : +050 2992 709
          </li>
          <li className="nav-item dropdown language-dropdown">
            <Dropdown>
              <Dropdown.Toggle className="nav-link count-indicator p-0 toggle-arrow-hide bg-transparent">
                <div className="d-inline-flex mr-0 mr-md-3">
                  <div className="flag-icon-holder">
                    <i className="flag-icon flag-icon-us"></i>
                  </div>
                </div>
                <span className="profile-text font-weight-medium d-none d-md-block">
                  English
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="navbar-dropdown preview-list">
                <Dropdown.Item
                  className="dropdown-item  d-flex align-items-center"
                  href="!#"
                  onClick={(evt) => evt.preventDefault()}
                >
                  <div className="flag-icon-holder">
                    <i className="flag-icon flag-icon-us"></i>
                  </div>
                  English
                </Dropdown.Item>
                <div className="dropdown-divider"></div>
                <Dropdown.Item
                  className="dropdown-item preview-item d-flex align-items-center"
                  href="!#"
                  onClick={(evt) => evt.preventDefault()}
                >
                  <div className="flag-icon-holder">
                    <i className="flag-icon flag-icon-fr"></i>
                  </div>
                  French
                </Dropdown.Item>
                <div className="dropdown-divider"></div>
                <Dropdown.Item
                  className="dropdown-item preview-item d-flex align-items-center"
                  href="!#"
                  onClick={(evt) => evt.preventDefault()}
                >
                  <div className="flag-icon-holder">
                    <i className="flag-icon flag-icon-ae"></i>
                  </div>
                  Arabic
                </Dropdown.Item>
                <div className="dropdown-divider"></div>
                <Dropdown.Item
                  className="dropdown-item preview-item d-flex align-items-center"
                  href="!#"
                  onClick={(evt) => evt.preventDefault()}
                >
                  <div className="flag-icon-holder">
                    <i className="flag-icon flag-icon-ru"></i>
                  </div>
                  Russian
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </ul>
        <form className="ml-auto search-form d-none d-md-block" action="#">
          <div className="form-group">
            <input
              type="search"
              className="form-control"
              placeholder="Search Here"
            />
          </div>
        </form>

        <ul className="navbar-nav navbar-nav-right">
          {auth.user?.role == "admin" || auth.user?.role == "sales" ? (
            <div className="position-relative">
              <button
                type="button"
                className="border-0 bg-transparent"
                onClick={opemModal}
              >
                <img
                  className="position-relative"
                  width={30}
                  height={30}
                  src={require("../../assets/images/bell.png")}
                  alt="notification"
                />
              </button>
              {notificationData.length > 0 && count > 0 ? (
                <div
                  className="bg-danger rounded-circle position-absolute"
                  style={{
                    width: 16,
                    height: 16,
                    top: 0,
                    left: 15,
                    paddingLeft: 4,
                  }}
                >
                  <h6>{count}</h6>
                </div>
              ) : null}
              <NotificationModal
                data={notificationData}
                show={showModal}
                setModal={setShowModal}
                fetchActive={getActiveNotifications}
              />
            </div>
          ) : null}
          <li className="nav-item  nav-profile border-0">
            <Dropdown>
              <Dropdown.Toggle className="nav-link count-indicator bg-transparent">
                <img
                  className="img-xs rounded-circle"
                  src={require("../../assets/images/faces/face8.jpg")}
                  alt="Profile"
                />
              </Dropdown.Toggle>
              <Dropdown.Menu className="preview-list navbar-dropdown pb-3">
                <Dropdown.Item
                  className="dropdown-item p-0 preview-item d-flex align-items-center border-bottom"
                  href="!#"
                  onClick={(evt) => evt.preventDefault()}
                >
                  <div className="d-flex">
                    <div className="py-3 px-4 d-flex align-items-center justify-content-center">
                      <i className="mdi mdi-bookmark-plus-outline mr-0"></i>
                    </div>
                    <div className="py-3 px-4 d-flex align-items-center justify-content-center border-left border-right">
                      <i className="mdi mdi-account-outline mr-0"></i>
                    </div>
                    <div className="py-3 px-4 d-flex align-items-center justify-content-center">
                      <i className="mdi mdi-alarm-check mr-0"></i>
                    </div>
                  </div>
                </Dropdown.Item>
                <Dropdown.Item
                  className="dropdown-item preview-item d-flex align-items-center border-0 mt-2"
                  onClick={(evt) => evt.preventDefault()}
                >
                  Manage Accounts
                </Dropdown.Item>
                <Dropdown.Item
                  className="dropdown-item preview-item d-flex align-items-center border-0"
                  onClick={(evt) => evt.preventDefault()}
                >
                  Change Password
                </Dropdown.Item>
                <Dropdown.Item
                  className="dropdown-item preview-item d-flex align-items-center border-0"
                  onClick={(evt) => evt.preventDefault()}
                >
                  Check Inbox
                </Dropdown.Item>
                <Dropdown.Item
                  className="dropdown-item preview-item d-flex align-items-center border-0"
                  onClick={(evt) => signOut()}
                >
                  Sign Out
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </ul>
        <button
          className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
          type="button"
          onClick={toggleOffcanvas}
        >
          <span className="mdi mdi-menu"></span>
        </button>
      </div>
    </nav>
  );
}
