import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Alert, Tabs, Tab } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Multiselect } from "multiselect-react-dropdown";
import DatePicker from "react-datepicker";

import { AgGridReact } from 'ag-grid-react';
import { useForm } from "react-hook-form";

import { alertActions, packagesActions, sharedActions } from "../../_actions";

import { packagesService, sharedService } from "../../_services";
import { configService } from "../../_services/config.service";
import { markInvalidPackages } from "../../_helpers/util";
import swal from "sweetalert";

const newObj = {
  code: "",
  description: "",
  type: "",
  value: 0,
  expirationDate: new Date().setMonth(new Date().getMonth() + 1),
  usageLimit: 0,
  usageCount: 0,
  packageIds: [],
};

function Config() {
  const [show, setShow] = useState(false);

  const [isUpdate, setIsUpdate] = useState(false);
  const [formError, setFormError] = useState("");

  // const [newCouponCode, setNewCouponCode] = useState(Object.assign(newObj));
  const [coverPhotoConfig, setCoverPhotoConfig] = useState({
    __config: { packages: [] },
  });
  const [iData, setIData] = useState({ packages: [] });
  const [coverPhotoChanges, setCoverPhotoChanges] = useState({});


  const [tabsView, setTabsView] = useState('home')

  const [showEdit, toggleEdit] = useState(false)

  const [selectedTravelType, setTravelType] = useState({})

  const [travelTypesGrid, setTravelTypesGrid] = useState({
    rowData: [],
    columnDefs: [],
    defaultColDef: {
      autoSizeColumns: true,
      suppressSizeToFit: true
    }
  })

  const {
    register,
    setError,
    clearErrors,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const handleClose = () => {
    setShow(false);
    setIsUpdate(false);
  };

  const authData = useSelector((state) => state.authentication);

  function onCoverPhotoSubmit() {
    const updateObj = coverPhotoConfig;
    updateObj.__config.packages = coverPhotoChanges.packages;
    configService
      .update(updateObj)
      .then(() => { })
      .catch((err) => { });
  }

  function onFieldChange({ field, value }) {
    setCoverPhotoChanges({
      ...coverPhotoChanges,
      [field]: value,
    });
    if (value) {
      clearErrors(field);
    }
  }

  async function getIData() {
    let packages = await packagesService.list();
    packages = packages.filter(
      (_package) => markInvalidPackages(_package).isValid
    );

    setIData({
      ...iData,
      packages: packages.map((_package) => {
        return { id: _package.id, name: _package.name };
      }),
    });
  }

  async function init() {
    reset();
    setIsUpdate(false);
    configService.getCoverPhoto().then((data) => {
      setCoverPhotoConfig(data[0]);
    });
    setFormError("");
  }

  useEffect(() => {
    init();
    getIData();
    alertActions.clear();

    getTravelTypes()
  }, []);

  const getTravelTypes = () => {
    configService.getTravelTypes(authData.user.id)
      .then((resp) => {
        setTravelTypesGrid({
          ...travelTypesGrid,
          rowData: resp
        })

        toggleEdit(false)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const deleteTravelType = (data) => {
    swal({
      title: 'Delete',
      text: 'Are you sure to delete?',
      icon:'warning',
      buttons: true,
      dangerMode: true
    })
      .then((isOkay) => {
        if (isOkay) {
          configService.deleteTravelType(data.id, authData.user.id)
            .then((resp) => {
              swal({
                title:'Success',
                text:'Travel type deleted successfully.',
                icon:'success'
              })

              getTravelTypes();
            })
        }
      })
  }

  const renderActions = ({ data }) => {
    return <div className="font-weight-bolder">
      <i className="fa fa-edit mr-3 text-info" onClick={()=> {
        setTravelType({...data});
        toggleEdit(true)
      }}></i>

      {/* <i className="fa fa-eye mr-3 text-primary"></i> */}

      <i className="fa fa-trash mr-3 text-danger" onClick={() => deleteTravelType(data)}></i>
    </div>
  }

  const colDefs = [
    { field: 'name', title: 'Name', resizable: true, sortable: true },
    { field: 'description', title: 'Description', resizable: true, sortable: true },
    { field: 'maxCapacity', title: 'Max Capacity', resizable: true, sortable: true },
    { field: 'Actions', title: 'Actions', cellRenderer: renderActions }
  ]

  const handleCloseEdit = () => {
    setTravelType({})
    toggleEdit(false)
  }

  const doUpdate = () => {
    configService.updateTravelType(selectedTravelType.id, authData.user.id, selectedTravelType)
    .then((resp)=>{
      swal({
        title:'Success',
        icon:'success',
        text:'Travel type updated successfully.'
      })
      getTravelTypes();
    })
  }

  const doAdd = () => {
    configService.addTravelType(authData.user.id, selectedTravelType)
    .then((resp)=>{
      swal({
        title:'Success',
        icon:'success',
        text:'Travel type added successfully.'
      })

      getTravelTypes();
    })
  }

  const updateTravelType = () => {
    return selectedTravelType.id ? doUpdate() : doAdd()
  }

  return (
    <div>
      <div className="row">
        <div className="col-sm-12 tab-container">
          <Tabs accessKey={tabsView} onSelect={(event) => {
            setTabsView(event)
          }}>
            <Tab eventKey="home" title="Conver Photo">
              <div className="row">
                <div className="col-sm-6">
                  <h2>Cover Photo Packages</h2>
                </div>
                <div className="col-sm-6">

                </div>
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <Multiselect
                    selectedValues={coverPhotoConfig.__config.packages}
                    options={iData.packages}
                    displayValue="name"
                    onSelect={(e) => onFieldChange({ field: "packages", value: e })}
                    onRemove={(e) => onFieldChange({ field: "packages", value: e })}
                  />
                </div>
                <div className="col-lg-2">
                  <button
                    type="button"
                    className="btn btn-primary mt-3"
                    onClick={onCoverPhotoSubmit}
                  >
                    Update
                  </button>
                </div>
              </div>
            </Tab>
            <Tab eventKey="travelTypes" title="Travel Types">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h2>Configure Travel Types</h2>
                </div>
                <div className="col-sm-6">
                  <div className="pull-right">
                    <button className="btn btn-sm btn-primary" onClick={()=> toggleEdit(true)}>Add Travel Type</button>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="ag-theme-alpine" style={{ height: '70vh', width: '100%' }}>
                    <AgGridReact
                      defaultColDef={travelTypesGrid.defaultColDef}
                      rowData={travelTypesGrid.rowData}
                      columnDefs={colDefs}>
                    </AgGridReact>
                  </div>
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>


      <Modal show={showEdit} onHide={handleCloseEdit}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Travel Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <input className="form-control form-control-sm" placeholder="Name" value={selectedTravelType.name} onChange={(event)=> {
              setTravelType({
                ...selectedTravelType,
                name: event.target.value
              })
            }} />
          </div>

          <div className="form-group">
            <textarea className="form-control form-control-sm" placeholder="Description" value={selectedTravelType.description} rows={6} onChange={(event)=> {
              setTravelType({
                ...selectedTravelType,
                description: event.target.value
              })
            }} />
          </div>

          <div className="form-group">
            <input className="form-control form-control-sm" placeholder="Max Capacity" value={selectedTravelType.maxCapacity} onChange={(event)=> {
              setTravelType({
                ...selectedTravelType,
                maxCapacity: event.target.value
              })
            }} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div>
          <button className="btn btn-sm mr-2" onClick={handleCloseEdit}>Cancel</button>
            <button className="btn btn-sm btn-primary" onClick={()=> {
              updateTravelType()
            }}>Save</button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Config;
