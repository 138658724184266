import React, { useEffect, useState, useRef } from "react";
import { Modal, Form } from "react-bootstrap";
import SimpleReactValidator from "simple-react-validator";
import { bookingsService } from "../../_services/bookings.service";
import TrainModal from "../Modals/TrainModal";
import BusModal from "../Modals/BusModal";
import VehicleModal from "../Modals/VehicleModal";
import StayModal from "../Modals/StayModal";
import AddOnsModal from "../Modals/AddOnsModal";
import { userService } from "../../_services";

const UpdateInfo = ({ getBookings, clearBooking, data }) => {
  const [shouldUpdate, setShouldUpdate] = useState(true);
  const [vendors, setVendor] = useState([]);
  const [showModal, toggleModal] = useState({
    updateModal: false,
    showTrainModal: false,
    showBusModal: false,
    showVehicleModal: false,
    showStayModal: false,
    showAddonModal: false,
  });
  const [booking, setBooking] = useState({
    customerDetails: {},
    coTravellers: [],
  });
  const [update, forceUpdate] = useState(false);
  const [accIndex, setAccIndex] = useState({
    accIndexInfo: -1,
    accIndexTrain: -1,
    accIndexBus: -1,
    accIndexVehicle: -1,
    accIndexStay: -1,
    accIndexAddon: -1,
  });

  useEffect(() => {
    if (data.id) {
      getBookingDetails();
      getVendorDetails();
    } else {
      toggleModal({ ...showModal, updateModal: false });
    }
  }, [data.id]);
  const validator = useRef(
    new SimpleReactValidator({
      element: (msg) => <small className="text-danger">{msg}</small>,
    })
  );
  const getVendorDetails = () => {
    userService.getByRole("vendor", {}).then((resp) => {
      setVendor(resp);
    });
  };
  const getBookingDetails = () => {
    bookingsService.getById(data.id).then((resp) => {
      if (!resp.coTravellers) {
        resp["coTravellers"] = Array.from(new Array(resp?.noOfUsers - 1)).map(
          (e) => {
            return {};
          }
        );
      }
      setBooking(resp);
      toggleModal({ ...showModal, updateModal: true });
    });
  };

  const closeModal = () => {
    toggleModal({ ...showModal, updateModal: false });
    clearBooking();
  };

  const handleChange = (key, value, index) => {
    let Obj = Object.assign({}, booking);
    if (Number.isInteger(index)) {
      Obj["coTravellers"][index][key] = value;
    } else {
      Obj[key] = value;
    }

    setBooking({
      ...Obj,
    });
  };

  const handleCustomerDetailsChange = (key, value) => {
    let Obj = Object.assign({}, booking);
    Obj.customerDetails[key] = value;
    setBooking({
      ...Obj,
    });
  };

  const renderCoTravellers = () => {
    return booking.coTravellers.map((e, i) => (
      <div className="accordion mb-1" id={`accordion${i}`} key={`cot-${i}`}>
        <div className="card">
          <div className="card-header" id={`heading${i}`}>
            <h2 className="mb-0">
              <button
                className="btn btn-link btn-block text-left text-capitalize"
                type="button"
                data-toggle="collapse"
                data-target={`#collapse${i}`}
                aria-expanded="true"
                aria-controls={`collapse${i}`}
                onClick={() =>
                  accIndex.accIndexInfo == i
                    ? setAccIndex({ ...accIndex, accIndexInfo: -1 })
                    : setAccIndex({ ...accIndex, accIndexInfo: i })
                }
              >
                {e.name || `Co-Traveller ${i + 1}`}{" "}
                {e.gender ? `(${e.gender})` : ""}
              </button>
            </h2>
          </div>

          <div
            id={`collapse${i}`}
            className={`collapse p-3 ${
              accIndex.accIndexInfo == i ? "show" : ""
            }`}
            aria-labelledby={`heading${i}`}
            data-parent={`#accordion${i}`}
          >
            <div className="form-group">
              <input
                className="form-control"
                placeholder="Name"
                defaultValue={e.name || ""}
                onChange={(event) =>
                  handleChange("name", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <input
                className="form-control"
                placeholder="Phone Number"
                value={e.phone || ""}
                onChange={(event) =>
                  handleChange("phone", event.target.value, i)
                }
              />
            </div>
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <input
                    className="form-control"
                    placeholder="Age"
                    value={e.age || ""}
                    onChange={(event) =>
                      handleChange("age", event.target.value, i)
                    }
                  />
                </div>
              </div>

              <div className="col">
                <div className="form-group">
                  <select
                    className="form-control"
                    value={e.gender || ""}
                    onChange={(event) =>
                      handleChange("gender", event.target.value, i)
                    }
                  >
                    <option>Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <input
                    className="form-control"
                    placeholder="Stay Type"
                    value={e.stayType}
                    onChange={(event) =>
                      handleChange("stayType", event.target.value, i)
                    }
                  />
                </div>
              </div>

              <div className="col">
                <div className="form-group">
                  <input
                    className="form-control"
                    placeholder="Travel Type"
                    value={e.travelType}
                    onChange={(event) =>
                      handleChange("travelType", event.target.value, i)
                    }
                  />
                </div>
              </div>
            </div>

            <div className="form-group">
              <input
                className="form-control"
                placeholder="Boarding Point"
                value={e.boardingPoint}
                onChange={(event) =>
                  handleChange("boardingPoint", event.target.value, i)
                }
              />
            </div>

            <div className="form-group">
              <input
                className="form-control"
                placeholder="Email Address"
                value={e.email}
                onChange={(event) =>
                  handleChange("email", event.target.value, i)
                }
              />
            </div>
          </div>
        </div>
      </div>
    ));
  };

  const handleUpdate = () => {
    if (!validator.current.allValid()) {
      validator.current.showMessages();
      return forceUpdate(!update);
    }
    if (shouldUpdate === true) {
      bookingsService.update(booking).then((resp) => {
        closeModal();
        getBookings();
      });
    } else {
      closeModal();
    }
  };
  return (
    <>
      <TrainModal
        show={showModal}
        setShow={toggleModal}
        accIndex={accIndex}
        vendorList={vendors}
        setUpdate={setShouldUpdate}
        setAccIndex={setAccIndex}
        Id={data.id}
      />
      <BusModal
        show={showModal}
        setShow={toggleModal}
        setUpdate={setShouldUpdate}
        accIndex={accIndex}
        vendorList={vendors}
        setAccIndex={setAccIndex}
        Id={data.id}
      />
      <VehicleModal
        show={showModal}
        setShow={toggleModal}
        setUpdate={setShouldUpdate}
        accIndex={accIndex}
        vendorList={vendors}
        setAccIndex={setAccIndex}
        Id={data.id}
      />
      <StayModal
        show={showModal}
        setShow={toggleModal}
        Id={data.id}
        setUpdate={setShouldUpdate}
        accIndex={accIndex}
        vendorList={vendors}
        setAccIndex={setAccIndex}
      />
      <AddOnsModal
        show={showModal}
        setShow={toggleModal}
        Id={data.id}
        setUpdate={setShouldUpdate}
        accIndex={accIndex}
        setAccIndex={setAccIndex}
      />
      <Modal
        show={showModal.updateModal}
        onHide={() => closeModal()}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="row">
              <div className="col">
                <div>
                  <b>Primary Contact</b>
                </div>
                <div className="form-group">
                  <label>Name</label>
                  <input
                    className="form-control"
                    placeholder="Name"
                    type="text"
                    value={booking.customerDetails.name}
                    onChange={(event) =>
                      handleCustomerDetailsChange("name", event.target.value)
                    }
                  />
                  {validator.current.message(
                    "name",
                    booking.customerDetails.name,
                    "required"
                  )}
                </div>
                <div className="form-group">
                  <label>Gender</label>
                  <select
                    className="form-control"
                    value={booking.customerDetails.gender}
                    onChange={(event) =>
                      handleCustomerDetailsChange("gender", event.target.value)
                    }
                  >
                    <option>Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </select>

                  {validator.current.message(
                    "gender",
                    booking.customerDetails.gender,
                    "required"
                  )}
                </div>

                <div className="form-group">
                  <label>Boarding Point</label>
                  <input
                    className="form-control"
                    type="text"
                    value={booking.boardingPoint}
                    placeholder="Boarding Point"
                    onChange={(event) =>
                      handleChange("boardingPoint", event.target.value, null)
                    }
                  />
                </div>

                <div className="form-group">
                  <label>Client Notes</label>
                  <textarea
                    className="form-control"
                    rows={8}
                    value={booking.clientNotes}
                    onChange={(event) =>
                      handleChange("clientNotes", event.target.value, null)
                    }
                  ></textarea>
                </div>
                <div className="d-flex justify-content-between">
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() =>
                      toggleModal({ ...showModal, showTrainModal: true })
                    }
                  >
                    Train
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() =>
                      toggleModal({ ...showModal, showBusModal: true })
                    }
                  >
                    Bus
                  </button>
                  <button
                    type="button"
                    className="btn btn-warning"
                    onClick={() =>
                      toggleModal({ ...showModal, showVehicleModal: true })
                    }
                  >
                    Vehicle
                  </button>
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={() =>
                      toggleModal({ ...showModal, showStayModal: true })
                    }
                  >
                    Stay
                  </button>
                  <button
                    type="button"
                    className="btn btn-info"
                    onClick={() =>
                      toggleModal({ ...showModal, showAddonModal: true })
                    }
                  >
                    Add Ons
                  </button>
                </div>
              </div>
              <div className="col">{renderCoTravellers()}</div>

              <div className="col">
                <div className="row">
                  <div className="col-sm-12">
                    <label>Comments / Notes</label>
                    <textarea
                      className="form-control"
                      rows={6}
                      value={booking.teamNotes}
                      onChange={(event) =>
                        handleChange("teamNotes", event.target.value, null)
                      }
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-sm btn-danger"
            onClick={() => closeModal()}
          >
            Close
          </button>
          <button
            className="btn btn-sm btn-primary"
            onClick={() => handleUpdate()}
          >
            Save Changes
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UpdateInfo;
