import React, { useState, useEffect } from "react";
import { Modal, Form } from "react-bootstrap";
import { bookingsService } from "../../_services/bookings.service";
import DatePicker from "react-datepicker";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
const BusModal = ({
  show,
  setShow,
  accIndex,
  setAccIndex,
  Id,
  setUpdate,
  vendorList,
}) => {
  const [busbooking, setBusBooking] = useState({
    busDetails: {
      traveller_name: "",
      travel_date: "",
      bus_type: "",
      driver_No: "",
      dep_time: "",
      amount: "",
      pick_point: "",
      bus_source: "",
      pnr_no: "",
      bus_dest: "",
      vendor_name: "",
      Notes: "",
    },
    coTravellers: [],
  });
  useEffect(() => {
    if (Id) {
      getBookingDetails();
    } else {
      closeModal();
    }
  }, [Id]);

  const getBookingDetails = () => {
    bookingsService.getById(Id).then((resp) => {
      if (!resp.coTravellers) {
        resp["coTravellers"] = Array.from(new Array(resp?.noOfUsers - 1)).map(
          (e) => {
            return {};
          }
        );
      }
      setBusBooking(resp);
    });
  };
  const handleModalChanges = () => {
    const Obj = {
      busDetails: busbooking.busDetails,
      coTravellers: busbooking.coTravellers,
      id: busbooking.id,
    };
    bookingsService.update(Obj).then((resp) => {
      setUpdate(false);
      closeModal();
    });
  };
  const handleCustomerBusDetailsChange = (key, info) => {
    let busDetails = { ...busbooking.busDetails };
    if (key == "vendor_name") {
      busDetails[key] = info.value;
    } else {
      busDetails[key] = info;
    }
    setBusBooking({ ...busbooking, busDetails });
  };
  const handleCoTravellerData = (key, info, index) => {
    let Obj = Object.assign({}, busbooking);
    if (Number.isInteger(index)) {
      if (key === "vendor_name") {
        Obj["coTravellers"][index].busDetails = {
          ...Obj["coTravellers"][index].busDetails,
          [key]: info.value,
        };
      } else {
        Obj["coTravellers"][index].busDetails = {
          ...Obj["coTravellers"][index].busDetails,
          [key]: info,
        };
      }
    }
    setBusBooking(Obj);
  };

  const closeModal = () => {
    setShow({ ...show, showBusModal: false });
  };

  const options = vendorList.map((vendor) => ({
    label: vendor.name || vendor.username,
    value: vendor.name || vendor.username,
  }));
  const renderCoTravellers = () => {
    return busbooking.coTravellers.map((e, i) => (
      <div className="accordion mb-1" id={`accordion${i}`} key={`cot-${i}`}>
        <div className="card">
          <div className="card-header" id={`heading${i}`}>
            <h2 className="mb-0">
              <button
                className="btn btn-link btn-block text-left text-capitalize"
                type="button"
                data-toggle="collapse"
                data-target={`#collapse${i}`}
                aria-expanded="true"
                aria-controls={`collapse${i}`}
                onClick={() =>
                  accIndex.accIndexBus == i
                    ? setAccIndex({ ...accIndex, accIndexBus: -1 })
                    : setAccIndex({ ...accIndex, accIndexBus: i })
                }
              >
                {e.name || `Co-Traveller ${i + 1}`}{" "}
                {e.gender ? `(${e.gender})` : ""}
              </button>
            </h2>
          </div>

          <div
            id={`collapse${i}`}
            className={`collapse p-3 ${
              accIndex.accIndexBus == i ? "show" : ""
            }`}
            aria-labelledby={`heading${i}`}
            data-parent={`#accordion${i}`}
          >
            <div className="form-group">
              <label>Traveller Name</label>
              <input
                className="form-control"
                placeholder="traveller name"
                value={e.busDetails?.traveller_name || ""}
                onChange={(event) =>
                  handleCoTravellerData("traveller_name", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>Date Of Travel</label>
              <div>
                <DatePicker
                  selected={e.busDetails?.travel_date === undefined ? '' : new Date(e.busDetails?.travel_date)}
                  onChange={(date) =>
                    handleCoTravellerData("travel_date", date, i)
                  }
                  dateFormat={"dd/MM/yyyy"}
                  minDate={new Date()}
                  placeholderText="select a date"
                />
              </div>
            </div>
            <div className="form-group">
              <label>Bus Type</label>
              <select
                className="form-control"
                value={e.busDetails?.bus_type || ""}
                onChange={(event) =>
                  handleCoTravellerData("bus_type", event.target.value, i)
                }
              >
                <option value="Choose Bus Type">Choose Bus Type</option>
                <option value="AC-Sleeper">AC-Sleeper</option>
                <option value="NON AC-Sleeper">NON AC-Sleeper</option>
                <option value="AC-Semi Sleeper">AC-Semi Sleeper</option>
                <option value="NON AC-Semi Sleeper">NON AC-Semi Sleeper</option>
              </select>
            </div>
            <div className="form-group react-select-custom">
              <label>Vendor</label>
              <Select
                placeholder="Vendor Name"
                noOptionsMessage={() => "No Vendors Found"}
                value={options.find((itx) => {
                  return itx.value === e.busDetails?.vendor_name;
                })}
                onChange={(event) => {
                  handleCoTravellerData("vendor_name", event, i);
                }}
                options={options}
              />
            </div>
            <div className="form-group">
              <label>Driver Number</label>
              <input
                className="form-control"
                placeholder="Driver Number"
                value={e.busDetails?.driver_No || ""}
                onChange={(event) =>
                  handleCoTravellerData("driver_No", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>PNR No</label>
              <input
                className="form-control"
                placeholder="PNR No"
                value={e.busDetails?.pnr_no || ""}
                onChange={(event) =>
                  handleCoTravellerData("pnr_no", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>Departure Time</label>
              <input
                className="form-control"
                placeholder="Departure time"
                value={e.busDetails?.dep_time || ""}
                onChange={(event) =>
                  handleCoTravellerData("dep_time", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>Bus Fare</label>
              <input
                className="form-control"
                placeholder="Bus Fare"
                value={e.busDetails?.amount || ""}
                onChange={(event) =>
                  handleCoTravellerData("amount", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>Pickup point</label>
              <input
                className="form-control"
                placeholder="pickup point"
                value={e.busDetails?.pick_point || ""}
                onChange={(event) =>
                  handleCoTravellerData("pick_point", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>Source</label>
              <input
                className="form-control"
                placeholder="Source"
                value={e.busDetails?.bus_source || ""}
                onChange={(event) =>
                  handleCoTravellerData("bus_source", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>Destination</label>
              <input
                className="form-control"
                placeholder="Destination"
                value={e.busDetails?.bus_dest || ""}
                onChange={(event) =>
                  handleCoTravellerData("bus_dest", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>Notes</label>
              <textarea
                className="form-control"
                rows={6}
                value={e.busDetails?.Notes || ""}
                onChange={(event) =>
                  handleCoTravellerData("Notes", event.target.value, i)
                }
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    ));
  };
  return (
    <Modal show={show.showBusModal} onHide={() => closeModal()} size="md">
      <Modal.Header closeButton>
        <Modal.Title>Update Bus Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="row">
            <div className="col">
              <div className="form-group">
                <label>Traveller Name</label>
                <input
                  className="form-control"
                  placeholder="traveller name"
                  value={busbooking.busDetails?.traveller_name}
                  onChange={(event) =>
                    handleCustomerBusDetailsChange(
                      "traveller_name",
                      event.target.value
                    )
                  }
                />
              </div>
              <div className="form-group">
                <label>Date Of Travel</label>
                <div>
                  <DatePicker
                    selected={
                      busbooking.busDetails?.travel_date === undefined
                        ? ""
                        : new Date(busbooking.busDetails?.travel_date)
                    }
                    onChange={(date) =>
                      handleCustomerBusDetailsChange("travel_date", date)
                    }
                    dateFormat={"dd/MM/yyyy"}
                    minDate={new Date()}
                    placeholderText="select a date"
                  />
                </div>
              </div>
              <div className="form-group">
                <label>Bus Type</label>
                <select
                  className="form-control"
                  value={busbooking.busDetails?.bus_type}
                  onChange={(event) =>
                    handleCustomerBusDetailsChange(
                      "bus_type",
                      event.target.value
                    )
                  }
                >
                  <option value="Choose Bus Type">Choose Bus Type</option>
                  <option value="AC-Sleeper">AC-Sleeper</option>
                  <option value="NON AC-Sleeper">NON AC-Sleeper</option>
                  <option value="AC-Semi Sleeper">AC-Semi Sleeper</option>
                  <option value="NON AC-Semi Sleeper">
                    NON AC-Semi Sleeper
                  </option>
                </select>
              </div>
              <div className="form-group react-select-custom">
                <label>Vendor</label>
                <Select
                  placeholder="Vendor Name"
                  noOptionsMessage={() => "No Vendors Found"}
                  value={options.find((itx) => {
                    return itx.value === busbooking.busDetails?.vendor_name;
                  })}
                  onChange={(event) => {
                    handleCustomerBusDetailsChange("vendor_name", event);
                  }}
                  options={options}
                />
              </div>
              <div className="form-group">
                <label>Driver Number</label>
                <input
                  className="form-control"
                  placeholder="Driver Number"
                  value={busbooking.busDetails?.driver_No}
                  onChange={(event) =>
                    handleCustomerBusDetailsChange(
                      "driver_No",
                      event.target.value
                    )
                  }
                />
              </div>
              <div className="form-group">
                <label>PNR No</label>
                <input
                  className="form-control"
                  placeholder="PNR No"
                  value={busbooking.busDetails?.pnr_no}
                  onChange={(event) =>
                    handleCustomerBusDetailsChange("pnr_no", event.target.value)
                  }
                />
              </div>
              <div className="form-group">
                <label>Departure Time</label>
                <input
                  className="form-control"
                  placeholder="Departure time"
                  value={busbooking.busDetails?.dep_time}
                  onChange={(event) =>
                    handleCustomerBusDetailsChange(
                      "dep_time",
                      event.target.value
                    )
                  }
                />
              </div>
              <div className="form-group">
                <label>Bus Fare</label>
                <input
                  className="form-control"
                  placeholder="Bus Fare"
                  value={busbooking.busDetails?.amount}
                  onChange={(event) =>
                    handleCustomerBusDetailsChange("amount", event.target.value)
                  }
                />
              </div>
              <div className="form-group">
                <label>Pickup point</label>
                <input
                  className="form-control"
                  placeholder="pickup point"
                  value={busbooking.busDetails?.pick_point}
                  onChange={(event) =>
                    handleCustomerBusDetailsChange(
                      "pick_point",
                      event.target.value
                    )
                  }
                />
              </div>
              <div className="form-group">
                <label>Source</label>
                <input
                  className="form-control"
                  placeholder="Source"
                  value={busbooking.busDetails?.bus_source}
                  onChange={(event) =>
                    handleCustomerBusDetailsChange(
                      "bus_source",
                      event.target.value
                    )
                  }
                />
              </div>
              <div className="form-group">
                <label>Destination</label>
                <input
                  className="form-control"
                  placeholder="Destination"
                  value={busbooking.busDetails?.bus_dest}
                  onChange={(event) =>
                    handleCustomerBusDetailsChange(
                      "bus_dest",
                      event.target.value
                    )
                  }
                />
              </div>
              <div className="form-group">
                <label>Notes</label>
                <textarea
                  className="form-control"
                  rows={6}
                  value={busbooking.busDetails?.Notes}
                  onChange={(event) =>
                    handleCustomerBusDetailsChange("Notes", event.target.value)
                  }
                ></textarea>
              </div>
            </div>
          </div>
          {busbooking.coTravellers.length > 0 && (
            <div>{renderCoTravellers()}</div>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-sm btn-danger"
          onClick={() => closeModal()}
        >
          Close
        </button>
        <button
          className="btn btn-sm btn-primary"
          onClick={() => handleModalChanges()}
        >
          Save Changes
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default BusModal;
