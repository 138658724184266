import React, { useState, useEffect } from "react";
import { Modal, Form } from "react-bootstrap";
import { bookingsService } from "../../_services/bookings.service";

const AddOnsModal = ({
  show,
  setAccIndex,
  accIndex,
  setShow,
  Id,
  setUpdate
}) => {
  const [addonbooking, setAddonBooking] = useState({
    addonDetails: {
      traveller_name: "",
      addon_type: "",
      addon_amt: "",
      Notes: ""
    },
    coTravellers: [],
  });
  useEffect(() => {
    if (Id) {
      getBookingDetails();
    } else {
      closeModal();
    }
  }, [Id]);

  const getBookingDetails = () => {
    bookingsService.getById(Id).then((resp) => {
      if (!resp.coTravellers) {
        resp["coTravellers"] = Array.from(new Array(resp?.noOfUsers - 1)).map(
          (e) => {
            return {};
          }
        );
      }
      setAddonBooking(resp);
    });
  };
  const handleModalChanges = () => {
    const Obj = {
      addonDetails: addonbooking.addonDetails,
      coTravellers: addonbooking.coTravellers,
      id: addonbooking.id,
    };
    bookingsService.update(Obj).then((resp) => {
      setUpdate(false);
      closeModal();
    });
  };
  const handleCustomerAddonDetailsChange = (key, value) => {
    let addonDetails = { ...addonbooking.addonDetails };
    addonDetails[key] = value;
    setAddonBooking({ ...addonbooking, addonDetails });
  };
  const handleCoTravellerData = (key, value, index) => {
    let Obj = Object.assign({}, addonbooking);
    if (Number.isInteger(index)) {
      Obj["coTravellers"][index].addonDetails = {
        ...Obj["coTravellers"][index].addonDetails,
        [key]: value,
      };
    }
    setAddonBooking(Obj);
  };
  const closeModal = () => {
    setShow({ ...show, showAddonModal: false });
  };
  const renderCoTravellers = () => {
    return addonbooking.coTravellers.map((e, i) => (
      <div className="accordion mb-1" id={`accordion${i}`} key={`cot-${i}`}>
        <div className="card">
          <div className="card-header" id={`heading${i}`}>
            <h2 className="mb-0">
              <button
                className="btn btn-link btn-block text-left text-capitalize"
                type="button"
                data-toggle="collapse"
                data-target={`#collapse${i}`}
                aria-expanded="true"
                aria-controls={`collapse${i}`}
                onClick={() =>
                  accIndex.accIndexAddon === i
                    ? setAccIndex({ ...accIndex, accIndexAddon: -1 })
                    : setAccIndex({ ...accIndex, accIndexAddon: i })
                }
              >
                {e.name || `Co-Traveller ${i + 1}`}{" "}
                {e.gender ? `(${e.gender})` : ""}
              </button>
            </h2>
          </div>

          <div
            id={`collapse${i}`}
            className={`collapse p-3 ${
              accIndex.accIndexAddon === i ? "show" : ""
            }`}
            aria-labelledby={`heading${i}`}
            data-parent={`#accordion${i}`}
          >
               <div className="form-group">
                <label>Traveller Name</label>
                <input
                  className="form-control"
                  placeholder="traveller name"
                  value={e.addonDetails?.traveller_name}
                  onChange={(event) =>
                    handleCoTravellerData(
                      "traveller_name",
                      event.target.value,i
                    )
                  }
                />
              </div>
            <div className="form-group">
              <label>Addon Type</label>
              <input
                className="form-control"
                value={e.addonDetails?.addon_type || ""}
                placeholder="addon type"
                onChange={(event) =>
                  handleCoTravellerData("addon_type", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>Addon Amount</label>
              <input
                className="form-control"
                placeholder="Addon Amount"
                value={e.addonDetails?.addon_amt || ""}
                onChange={(event) =>
                  handleCoTravellerData("addon_amt", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>Notes</label>
              <textarea
                className="form-control"
                rows={6}
                value={e.addonDetails?.Notes}
                onChange={(event) =>
                  handleCoTravellerData("Notes", event.target.value, i)
                }
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    ));
  };
  return (
    <Modal show={show.showAddonModal} onHide={() => closeModal()} size="md">
      <Modal.Header closeButton>
        <Modal.Title>Update Addon Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="row">
            <div className="col">
            <div className="form-group">
                <label>Traveller Name</label>
                <input
                  className="form-control"
                  placeholder="traveller name"
                  value={addonbooking.addonDetails?.traveller_name}
                  onChange={(event) =>
                    handleCustomerAddonDetailsChange(
                      "traveller_name",
                      event.target.value
                    )
                  }
                />
              </div>
              <div className="form-group">
                <label>Addon Type</label>
                <input
                  className="form-control"
                  value={addonbooking.addonDetails?.addon_type}
                  placeholder="addon type"
                  onChange={(event) =>
                    handleCustomerAddonDetailsChange(
                      "addon_type",
                      event.target.value
                    )
                  }
                />
              </div>
              <div className="form-group">
                <label>Addon Amount</label>
                <input
                  className="form-control"
                  placeholder="Addon Amount"
                  value={addonbooking.addonDetails?.addon_amt}
                  onChange={(event) =>
                    handleCustomerAddonDetailsChange(
                      "addon_amt",
                      event.target.value
                    )
                  }
                />
              </div>
              <div className="form-group">
              <label>Notes</label>
              <textarea
                className="form-control"
                rows={6}
                value={addonbooking.addonDetails?.Notes}
                onChange={(event) =>
                  handleCustomerAddonDetailsChange("Notes", event.target.value)
                }
              ></textarea>
            </div>
            </div>
          </div>
          {addonbooking.coTravellers.length > 0 && (
            <div>{renderCoTravellers()}</div>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-sm btn-danger"
          onClick={() => closeModal()}
        >
          Close
        </button>
        <button
          className="btn btn-sm btn-primary"
          onClick={() => handleModalChanges()}
        >
          Save Changes
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddOnsModal;
