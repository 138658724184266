import config from "./config.json";
import { authHeader } from "../_helpers";
import { userActions } from "../_actions";
import axios from "axios";
config.apiUrl = process.env.REACT_APP_API_URL;

export const costCentersService = {
  add,
  list,
  update,
  getById,
  deleteById,
};

async function add(newCostCenter) {
  let user = JSON.parse(localStorage.getItem("user"));
  const response = await axios.post(
    `${config.apiUrl}/cost-centers`,
    newCostCenter,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: user.id,
      },
    }
  );
  handleResponse(response);
}

function list(tripId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${config.apiUrl}/cost-centers?filter=${JSON.stringify({
      where: { tripId },
      include: ["package", "trip", "travelType", "stayType"],
    })}`,
    requestOptions
  ).then(handleResponse);
}

function getById(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${config.apiUrl}/cost-centers/${id}?filter=${JSON.stringify({
      include: ["trip", "package", "travelType", "stayType"],
    })}`,
    requestOptions
  ).then(handleResponse);
}

async function update(updateCostCenter) {
  let user = JSON.parse(localStorage.getItem("user"));
  await axios.patch(
    `${config.apiUrl}/cost-centers/${updateCostCenter.id}`,
    updateCostCenter,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: user.id,
      },
    }
  );
}

function deleteById(id) {
  const requestOptions = {
    method: "DELETE",
    headers: { ...authHeader() },
  };

  return fetch(`${config.apiUrl}/cost-centers/${id}`, requestOptions).then(
    handleResponse
  );
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        userActions.logout();
        // location.reload(true);
      }

      const error = (data && data.error) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
