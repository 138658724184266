import React, { useEffect, useState } from "react";
import { bookingsService } from "../../_services/bookings.service";
import { userService } from "../../_services";
import { useSelector } from "react-redux";
import moment from "moment";
const VendorReport = () => {
  const [vendorList, setVendorList] = useState([]);
  const [user, setUser] = useState("");
  let userData = useSelector((state) => state.authentication);
  const getBookings = async () => {
    const bookings = await bookingsService.list({ limit: 10000 });
    setVendorList([...bookings]);
  };
  useEffect(() => {
    getBookings();
    getUser();
  }, []);
  async function getUser() {
    await userService.getById(userData.user.userId).then((response) => {
      setUser(response);
    });
  }
  let USER = user.name === undefined ? "" : user.name;
  let filteredData = vendorList.filter((item) => {
    return (
      (item.vendorDetails && item.vendorDetails?.vendorName == USER) ||
      (item.vendorDetails && item.vendorDetails?.vendorName == user.username)
    );
  });

  let filteredCoTraveller = filteredData.map((item) => {
    let traveller = item.vendorDetails?.coTravellers.find((item) => {
      return item.vendorName === USER || item.vendorName === user.username;
    });
    return traveller;
  });
   if (!filteredCoTraveller.some((traveller) => traveller !== undefined)) {
      filteredCoTraveller = [];
   }
  return (
    <div className="row mt-3">
      <div className="col-sm-12 table-responsive">
        <table className="table table-sm">
          <thead>
            <tr className="bg-warning">
              <th scope="col">Name</th>
              <th scope="col">Hotel Name</th>
              <th scope="col">No Of Travellers</th>
              <th scope="col">Manager Number</th>
              <th scope="col">Duration</th>
              <th scope="col">Check In Date</th>
              <th scope="col">Book Amount</th>
              <th scope="col"> Location</th>
              <th scope="col">Meal Plan</th>
            </tr>
          </thead>

          <tbody>
            {filteredData.length > 0 ? (
              filteredData.map((item, i) => (
                <>
                  <tr key={i}>
                    <td>{item.vendorDetails?.customerName}</td>
                    <td>{item.vendorDetails?.hotel_name}</td>
                    <td>{item.coTravellers?.length}</td>
                    <td>{item.vendorDetails?.manager_No}</td>
                    <td>{item.vendorDetails?.noOfDays} Days</td>
                    <td>
                      {moment(item.vendorDetails?.checkIn_date).format(
                        "DD MMM YYYY"
                      )}
                    </td>
                    <td>{item.vendorDetails?.booking_amount}</td>
                    <td>{item.vendorDetails?.location}</td>
                    <td>{item.vendorDetails?.meal_plan}</td>
                  </tr>

                  {filteredCoTraveller.length > 0
                    ? filteredCoTraveller.map((itx, index) => (
                        <tr key={index}>
                          <td>{itx?.customerName}</td>
                          <td>{itx?.hotel_name}</td>
                          <td>{0}</td>
                          <td>{itx?.manager_No}</td>
                          <td>{itx?.noOfDays} Days</td>
                          <td>
                            {moment(itx?.checkIn_date).format("DD MMM YYYY")}
                          </td>
                          <td>{itx?.booking_amount}</td>
                          <td>{itx?.location}</td>
                          <td>{itx?.meal_plan}</td>
                        </tr>
                      ))
                    : null}
                </>
              ))
            ) : (
              <tr>
                <td colSpan={10} className="text-center">
                  No Booking found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default VendorReport;
