import React, { useState, useEffect } from "react";
import { Modal, Form } from "react-bootstrap";
import { bookingsService } from "../../_services/bookings.service";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const StayModal = ({
  show,
  setShow,
  accIndex,
  setAccIndex,
  Id,
  setUpdate,
  vendorList,
}) => {
  const [staybooking, setStayBooking] = useState({
    stayDetails: {
      traveller_name: "",
      noOfDays: "",
      checkIn_date: "",
      hotel_name: "",
      owner_name: "",
      manager_No: "",
      location: "",
      sharing_type: "",
      amount: "",
      room_no: "",
      Notes: "",
      meal_plan: "",
    },
    vendorDetails: {
      customerName: "",
      vendorName: "",
      noOfDays: "",
      checkIn_date: "",
      hotel_name: "",
      manager_No: "",
      location: "",
      booking_amount: "",
      meal_plan: "",
      coTravellers: [],
    },
    coTravellers: [],
  });
  useEffect(() => {
    if (Id) {
      getBookingDetails();
    } else {
      closeModal();
    }
  }, [Id]);

  const getBookingDetails = () => {
    bookingsService.getById(Id).then((resp) => {
      if (!resp.coTravellers) {
        resp["coTravellers"] = Array.from(new Array(resp?.noOfUsers - 1)).map(
          (e) => {
            return {};
          }
        );
      }
      setStayBooking(resp);
    });
  };

  const handleCustomerStayDetailsChange = (key, info) => {
    let stayDetails = { ...staybooking.stayDetails };
    if (key == "owner_name") {
      stayDetails["owner_name"] = info.value;
    } else {
      stayDetails[key] = info;
    }
    let vendorDetails = { ...staybooking.vendorDetails };
    vendorDetails["customerName"] = staybooking.customerDetails.name;
    vendorDetails["vendorName"] = stayDetails.owner_name;
    vendorDetails["noOfDays"] = stayDetails.noOfDays;
    vendorDetails["checkIn_date"] = stayDetails.checkIn_date;
    vendorDetails["hotel_name"] = stayDetails.hotel_name;
    vendorDetails["manager_No"] = stayDetails.manager_No;
    vendorDetails["location"] = stayDetails.location;
    vendorDetails["booking_amount"] = stayDetails.amount;
    vendorDetails["meal_plan"] = stayDetails.meal_plan;
    vendorDetails["coTravellers"] = [];
    setStayBooking({ ...staybooking, stayDetails, vendorDetails });
  };

  const handleCoTravellerData = (key, info, index) => {
    let Obj = Object.assign({}, staybooking);
    if (Number.isInteger(index)) {
      if (key === "owner_name") {
        Obj["coTravellers"][index].stayDetails = {
          ...Obj["coTravellers"][index].stayDetails,
          ["owner_name"]: info.value,
        };
      } else {
        Obj["coTravellers"][index].stayDetails = {
          ...Obj["coTravellers"][index].stayDetails,
          [key]: info,
        };
      }

      const CoTravellerVendorDetails = {
        customerName: Obj["coTravellers"][index].name,
        vendorName: Obj["coTravellers"][index].stayDetails.owner_name,
        noOfDays: Obj["coTravellers"][index].stayDetails.noOfDays,
        checkIn_date: Obj["coTravellers"][index].stayDetails.checkIn_date,
        hotel_name: Obj["coTravellers"][index].stayDetails.hotel_name,
        manager_No: Obj["coTravellers"][index].stayDetails.manager_No,
        location: Obj["coTravellers"][index].stayDetails.location,
        booking_amount: Obj["coTravellers"][index].stayDetails.amount,
        meal_plan: Obj["coTravellers"][index].stayDetails.meal_plan,
      };
      Obj["vendorDetails"]["coTravellers"][index] = CoTravellerVendorDetails;
    }
    setStayBooking(Obj);
  };
  const handleModalChanges = () => {
    const data = {
      stayDetails: staybooking.stayDetails,
      coTravellers: staybooking.coTravellers,
      vendorDetails: staybooking.vendorDetails,
      id: staybooking.id,
    };
    bookingsService.update(data).then((resp) => {
      setUpdate(false);
      closeModal();
    });
  };

  const closeModal = () => {
    setShow({ ...show, showStayModal: false });
  };
  const options = vendorList.map((vendor) => ({
    label: vendor.name || vendor.username,
    value: vendor.name || vendor.username,
  }));
  const renderCoTravellers = () => {
    return staybooking.coTravellers.map((e, i) => (
      <div className="accordion mb-1" id={`accordion${i}`} key={`cot-${i}`}>
        <div className="card">
          <div className="card-header" id={`heading${i}`}>
            <h2 className="mb-0">
              <button
                className="btn btn-link btn-block text-left text-capitalize"
                type="button"
                data-toggle="collapse"
                data-target={`#collapse${i}`}
                aria-expanded="true"
                aria-controls={`collapse${i}`}
                onClick={() =>
                  accIndex.accIndexStay == i
                    ? setAccIndex({ ...accIndex, accIndexStay: -1 })
                    : setAccIndex({ ...accIndex, accIndexStay: i })
                }
              >
                {e.name || `Co-Traveller ${i + 1}`}{" "}
                {e.gender ? `(${e.gender})` : ""}
              </button>
            </h2>
          </div>

          <div
            id={`collapse${i}`}
            className={`collapse p-3 ${
              accIndex.accIndexStay == i ? "show" : ""
            }`}
            aria-labelledby={`heading${i}`}
            data-parent={`#accordion${i}`}
          >
            <div className="form-group">
              <label>Traveller Name</label>
              <input
                className="form-control"
                placeholder="traveller name"
                value={e.stayDetails?.traveller_name || ""}
                onChange={(event) =>
                  handleCoTravellerData("traveller_name", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>Number of Days</label>
              <input
                className="form-control"
                placeholder="number of days"
                value={e.stayDetails?.noOfDays || ""}
                onChange={(event) =>
                  handleCoTravellerData("noOfDays", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>Date Of Check In</label>
              <div>
                <DatePicker
                  selected={
                    e.stayDetails?.checkIn_date === undefined
                      ? ""
                      : new Date(e.stayDetails?.checkIn_date)
                  }
                  onChange={(date) =>
                    handleCoTravellerData("checkIn_date", date, i)
                  }
                  timeFormat="HH:mm"
                  dateFormat={"dd/MM/yyyy"}
                  minDate={new Date()}
                  placeholderText="select a date"
                />
              </div>
            </div>
            <div className="form-group">
              <label>Hotel Name</label>
              <input
                className="form-control"
                placeholder="hotel name"
                value={e.stayDetails?.hotel_name || ""}
                onChange={(event) =>
                  handleCoTravellerData("hotel_name", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>Room No</label>
              <input
                className="form-control"
                placeholder="room no"
                value={e.stayDetails?.room_no || ""}
                onChange={(event) =>
                  handleCoTravellerData("room_no", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>Sharing Type</label>
              <input
                className="form-control"
                placeholder="sharing type"
                value={
                  staybooking?.stayType || "No Stay Type Found"
                }
                onChange={(event) =>
                  handleCoTravellerData("sharing-type", event.target.value, i)
                }
              />
            </div>
            <div className="form-group react-select-custom">
              <label>Owner Name</label>
              <Select
                placeholder="Owner Name"
                noOptionsMessage={() => "No Vendors Found"}
                value={options.find((itx) => {
                  return itx.value === e.stayDetails?.owner_name;
                })}
                onChange={(event) => {
                  handleCoTravellerData("owner_name", event, i);
                }}
                options={options}
              />
            </div>

            <div className="form-group">
              <label>Manager Number</label>
              <input
                className="form-control"
                placeholder="manager number"
                value={e.stayDetails?.manager_No || ""}
                onChange={(event) =>
                  handleCoTravellerData("manager_No", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>Location</label>
              <input
                className="form-control"
                placeholder="Location"
                value={e.stayDetails?.location || ""}
                onChange={(event) =>
                  handleCoTravellerData("location", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>Amount</label>
              <input
                className="form-control"
                placeholder="amount"
                value={e.stayDetails?.amount || ""}
                onChange={(event) =>
                  handleCoTravellerData("amount", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>Meal Plan</label>
              <input
                className="form-control"
                placeholder="Meal Plan"
                value={e.stayDetails?.meal_plan || ""}
                onChange={(event) =>
                  handleCoTravellerData("meal_plan", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>Notes</label>
              <textarea
                className="form-control"
                rows={6}
                value={e.stayDetails?.Notes || ""}
                onChange={(event) =>
                  handleCoTravellerData("Notes", event.target.value, i)
                }
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    ));
  };
  return (
    <Modal show={show.showStayModal} onHide={() => closeModal()} size="md">
      <Modal.Header closeButton>
        <Modal.Title>Update Stay Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="row">
            <div className="col">
              <div className="form-group">
                <label>Traveller Name</label>
                <input
                  className="form-control"
                  placeholder="traveller name"
                  value={staybooking.stayDetails?.traveller_name}
                  onChange={(event) =>
                    handleCustomerStayDetailsChange(
                      "traveller_name",
                      event.target.value
                    )
                  }
                />
              </div>
              <div className="form-group">
                <label>Number of Days</label>
                <input
                  className="form-control"
                  placeholder="number of days"
                  value={staybooking.stayDetails?.noOfDays}
                  onChange={(event) =>
                    handleCustomerStayDetailsChange(
                      "noOfDays",
                      event.target.value
                    )
                  }
                />
              </div>
              <div className="form-group">
                <label>Date Of Check In</label>
                <div>
                  <DatePicker
                    selected={
                      staybooking.stayDetails?.checkIn_date === undefined
                        ? ""
                        : new Date(staybooking.stayDetails?.checkIn_date)
                    }
                    onChange={(date) =>
                      handleCustomerStayDetailsChange("checkIn_date", date)
                    }
                    dateFormat={"dd/MM/yyyy"}
                    minDate={new Date()}
                    placeholderText="select a date"
                  />
                </div>
              </div>
              <div className="form-group">
                <label>Hotel Name</label>
                <input
                  className="form-control"
                  placeholder="hotel name"
                  value={staybooking.stayDetails?.hotel_name}
                  onChange={(event) =>
                    handleCustomerStayDetailsChange(
                      "hotel_name",
                      event.target.value
                    )
                  }
                />
              </div>
              <div className="form-group">
                <label>Room No</label>
                <input
                  className="form-control"
                  placeholder="room no"
                  value={staybooking.stayDetails?.room_no}
                  onChange={(event) =>
                    handleCustomerStayDetailsChange(
                      "room_no",
                      event.target.value
                    )
                  }
                />
              </div>
              <div className="form-group">
                <label>Sharing Type</label>
                <input
                  className="form-control"
                  placeholder="sharing type"
                  value={
                    staybooking?.stayType || "No Stay Type Found"
                  }
                  onChange={(event) =>
                    handleCustomerStayDetailsChange(
                      "sharing_type",
                      event.target.value
                    )
                  }
                />
              </div>
              <div className="form-group react-select-custom">
                <label>Owner Name</label>
                <Select
                  placeholder="Owner Name"
                  noOptionsMessage={() => "No Vendors Found"}
                  value={options.find((itx) => {
                    return itx.value === staybooking.stayDetails?.owner_name;
                  })}
                  onChange={(event) => {
                    handleCustomerStayDetailsChange("owner_name", event);
                  }}
                  options={options}
                />
              </div>
              <div className="form-group">
                <label>Manager Number</label>
                <input
                  className="form-control"
                  placeholder="manager number"
                  value={staybooking.stayDetails?.manager_No}
                  onChange={(event) =>
                    handleCustomerStayDetailsChange(
                      "manager_No",
                      event.target.value
                    )
                  }
                />
              </div>
              <div className="form-group">
                <label>Location</label>
                <input
                  className="form-control"
                  placeholder="Location"
                  value={staybooking.stayDetails?.location}
                  onChange={(event) =>
                    handleCustomerStayDetailsChange(
                      "location",
                      event.target.value
                    )
                  }
                />
              </div>
              <div className="form-group">
                <label>Amount</label>
                <input
                  className="form-control"
                  placeholder="amount"
                  value={staybooking.stayDetails?.amount}
                  onChange={(event) =>
                    handleCustomerStayDetailsChange(
                      "amount",
                      event.target.value
                    )
                  }
                />
              </div>
              <div className="form-group">
                <label>Meal Plan</label>
                <input
                  className="form-control"
                  placeholder="Meal Plan"
                  value={staybooking.stayDetails?.meal_plan}
                  onChange={(event) =>
                    handleCustomerStayDetailsChange(
                      "meal_plan",
                      event.target.value
                    )
                  }
                />
              </div>
              <div className="form-group">
                <label>Notes</label>
                <textarea
                  className="form-control"
                  rows={6}
                  value={staybooking.stayDetails?.Notes}
                  onChange={(event) =>
                    handleCustomerStayDetailsChange("Notes", event.target.value)
                  }
                ></textarea>
              </div>
            </div>
          </div>
          {staybooking.coTravellers.length > 0 && (
            <div>{renderCoTravellers()}</div>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-sm btn-danger"
          onClick={() => closeModal()}
        >
          Close
        </button>
        <button
          className="btn btn-sm btn-primary"
          onClick={() => handleModalChanges()}
        >
          Save Changes
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default StayModal;
