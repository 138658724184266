import React, { useState, useEffect } from "react";
import { Modal, Form, Tabs, Tab } from "react-bootstrap";
import { bookingsService } from "../../_services/bookings.service";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
const TrainModal = ({
  show,
  setShow,
  accIndex,
  setAccIndex,
  Id,
  setUpdate,
  vendorList,
}) => {
  const [trainbooking, setTrainBooking] = useState({
    trainDetails: {
      trainOnwardDetails: {
        traveller_name: "",
        travel_date: "",
        train_name: "",
        seat_class: "",
        pnr_no: "",
        vendor_name: "",
        arrival_time: "",
        depart_time: "",
        train_source: "",
        train_dest: "",
        ticket_amt: "",
        train_coachNo: "",
        train_berthNo: "",
        Notes: "",
      },
      trainReturnDetails: {
        traveller_return_name: "",
        travel_return_date: "",
        train_return_name: "",
        seat_return_class: "",
        pnr_return_no: "",
        vendor_return_name: "",
        arrival_return_time: "",
        depart_return_time: "",
        train_return_source: "",
        train_return_dest: "",
        ticket_return_amt: "",
        train_return_coachNo: "",
        train_return_berthNo: "",
        return_Notes: "",
      },
    },
    coTravellers: [],
  });
  const [Activekey, setActiveKey] = useState("onward");
  const [isChecked, setIsChecked] = useState(false);
  useEffect(() => {
    if (Id) {
      getBookingDetails();
    } else {
      closeModal();
    }
  }, [Id]);

  const getBookingDetails = () => {
    bookingsService.getById(Id).then((resp) => {
      if (!resp.coTravellers) {
        resp["coTravellers"] = Array.from(new Array(resp?.noOfUsers - 1)).map(
          (e) => {
            return {};
          }
        );
      }
      setTrainBooking(resp);
      let returnApplicable = resp.trainDetails?.isReturnApplicable === undefined ? false : resp.trainDetails.isReturnApplicable
      setIsChecked(returnApplicable);
    });
  };

  const handleModalChanges = () => {
    const Obj = {
      trainDetails: trainbooking.trainDetails,
      coTravellers: trainbooking.coTravellers,
      id: trainbooking.id,
    };
    bookingsService.update(Obj).then((resp) => {
      setUpdate(false);
      closeModal();
    });
  };

  const handleCustomerTrainDetailsChange = (key, info) => {
    let trainOnwardDetails = {
      ...trainbooking.trainDetails?.trainOnwardDetails,
    };
    let trainReturnDetails = {
      ...trainbooking.trainDetails?.trainReturnDetails,
    };

    if (key === "vendor_name" && Activekey === "onward") {
      trainOnwardDetails = {
        ...trainOnwardDetails,
        [key]: info.value,
      };
    }

    if (key !== "vendor_name" && Activekey === "onward") {
      trainOnwardDetails = {
        ...trainOnwardDetails,
        [key]: info,
      };
    }

    if (key === "vendor_return_name" && Activekey === "return") {
      trainReturnDetails = {
        ...trainReturnDetails,
        [key]: info.value,
      };
    }
    if (key !== "vendor_return_name" && Activekey === "return") {
      trainReturnDetails = {
        ...trainReturnDetails,
        [key]: info,
      };
    }

    if (isChecked === false && Activekey === "return") {
      setTrainBooking({
        ...trainbooking,
        trainDetails: {
          isReturnApplicable: false,
          ...trainbooking.trainDetails,
          trainReturnDetails,
        },
      });
    }
    if (
      isChecked === false &&
      Activekey === "onward" &&
      trainbooking.trainDetails?.trainReturnDetails !== undefined
    ) {
      setTrainBooking({
        ...trainbooking,
        trainDetails: {
          isReturnApplicable: false,
          ...trainbooking.trainDetails,
          trainOnwardDetails,
        },
      });
    }
    if (
      isChecked === false &&
      Activekey === "onward" &&
      trainbooking.trainDetails?.trainReturnDetails === undefined
    ) {
      setTrainBooking({
        ...trainbooking,
        trainDetails: {
          isReturnApplicable: true,
          ...trainbooking.trainDetails,
          trainOnwardDetails,
        },
      });
    }
  };

  const handleCoTravellerData = (key, info, index) => {
    let Obj = { ...trainbooking};
    if (Number.isInteger(index)) {

      if (key === "vendor_name" && Activekey === "onward" ) {
        Obj.coTravellers[index].trainDetails = {
          ...Obj.coTravellers[index].trainDetails,
          trainOnwardDetails: {
            ...(Obj.coTravellers[index].trainDetails?.trainOnwardDetails || {}),
            [key]: info.value,
          },
        };
      }

      if(key !== "vendor_name" && Activekey === "onward") {
        Obj.coTravellers[index].trainDetails = {
          ...Obj.coTravellers[index].trainDetails,
          trainOnwardDetails: {
            ...(Obj.coTravellers[index].trainDetails?.trainOnwardDetails || {}),
            [key]: info,
          },
        };
      }

      if (key == "vendor_return_name" && Activekey === "return") {
        Obj.coTravellers[index].trainDetails = {
          ...Obj.coTravellers[index].trainDetails,
          trainReturnDetails: {
            ...(Obj.coTravellers[index].trainDetails?.trainReturndDetails || {}),
            [key]: info.value,
          },
        };
      }
      if (key !== "vendor_return_name" && Activekey === "return") {
        Obj.coTravellers[index].trainDetails = {
          ...Obj.coTravellers[index].trainDetails,
          trainReturnDetails: {
            ...(Obj.coTravellers[index].trainDetails?.trainReturnDetails || {}),
            [key]: info,
          },
        };
      }
    }
    if (isChecked === false && Activekey === "return") {
      setTrainBooking(Obj);
    }
    if (
      isChecked === false &&
      Activekey === "onward" &&
      Obj["coTravellers"][index].trainDetails.trainReturnDetails !== undefined
    ) { 
      setTrainBooking(Obj);
    }
    if (
      isChecked === false &&
      Activekey === "onward" &&
      Obj["coTravellers"][index].trainDetails.trainReturnDetails === undefined
    ) { 
      setTrainBooking(Obj);
    }
  };

  const closeModal = () => {
    setShow({ ...show, showTrainModal: false });
  };
  const options = vendorList.map((vendor) => ({
    label: vendor.name || vendor.username,
    value: vendor.name || vendor.username,
  }));
  console.log("Checked Value is", isChecked);
  const renderReturnCoTravellers = () => {
    return trainbooking.coTravellers.map((e, i) => (
      <div className="accordion mb-1" id={`accordion${i}`} key={`cot-${i}`}>
        <div className="card">
          <div className="card-header" id={`heading${i}`}>
            <h2 className="mb-0">
              <button
                className="btn btn-link btn-block text-left text-capitalize"
                type="button"
                data-toggle="collapse"
                data-target={`#collapse${i}`}
                aria-expanded="true"
                aria-controls={`collapse${i}`}
                onClick={() =>
                  accIndex.accIndexTrain == i
                    ? setAccIndex({ ...accIndex, accIndexTrain: -1 })
                    : setAccIndex({ ...accIndex, accIndexTrain: i })
                }
              >
                {e.name || `Co-Traveller ${i + 1}`}{" "}
                {e.gender ? `(${e.gender})` : ""}
              </button>
            </h2>
          </div>

          <div
            id={`collapse${i}`}
            className={`collapse p-3 ${
              accIndex.accIndexTrain == i ? "show" : ""
            }`}
            aria-labelledby={`heading${i}`}
            data-parent={`#accordion${i}`}
          >
            <div className="form-group">
              <label>Traveller Name</label>
              <input
                className="form-control"
                placeholder="traveller name"
                disabled={isChecked}
                value={e.trainDetails?.trainReturnDetails?.traveller_return_name || ""}
                onChange={(event) =>
                  handleCoTravellerData("traveller_return_name", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>Date Of Travel</label>
              <div>
                <DatePicker
                  selected={
                    e.trainDetails?.trainReturnDetails?.travel_return_date === undefined
                      ? ""
                      : new Date(e.trainDetails?.trainReturnDetails?.travel_return_date)
                  }
                  onChange={(date) =>
                    handleCoTravellerData("travel_return_date", date, i)
                  }
                  disabled={isChecked}
                  dateFormat={"dd/MM/yyyy"}
                  minDate={new Date()}
                  placeholderText="select a date"
                />
              </div>
            </div>
            <div className="form-group">
              <label>Train Name</label>
              <input
                className="form-control"
                placeholder="train name"
                disabled={isChecked}
                value={e.trainDetails?.trainReturnDetails?.train_return_name || ""}
                onChange={(event) =>
                  handleCoTravellerData("train_return_name", event.target.value, i)
                }
              />
            </div>
            <div className="form-group react-select-custom">
              <label>Vendor</label>
              <Select
                placeholder="Vendor Name"
                noOptionsMessage={() => "No Vendors Found"}
                value={options.find((itx) => {
                  return itx.value === e.trainDetails?.trainReturnDetails?.vendor_return_name;
                })}
                isDisabled={isChecked}
                onChange={(event) => {
                  handleCoTravellerData("vendor_return_name", event, i);
                }}
                options={options}
              />
            </div>
            <div className="form-group">
              <label>Class</label>
              <select
                className="form-control"
                disabled={isChecked}
                value={e.trainDetails?.trainReturnDetails?.seat_return_class}
                onChange={(event) =>
                  handleCoTravellerData("seat_return_class", event.target.value, i)
                }
              >
                <option value="Choose Class Type">Choose Class Type</option>
                <option value="Seater">Seater</option>
                <option value="Sleeper">Sleeper</option>
                <option value="2AC">2AC</option>
                <option value="3AC">3AC</option>
                <option value="1AC">1AC</option>
              </select>
            </div>
            <div className="form-group">
              <label>Source</label>
              <input
                className="form-control"
                placeholder="Source"
                disabled={isChecked}
                value={e.trainDetails?.trainReturnDetails?.train_return_source || ""}
                onChange={(event) =>
                  handleCoTravellerData("train_return_source", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>Destination</label>
              <input
                className="form-control"
                placeholder="Destination"
                disabled={isChecked}
                value={e.trainDetails?.trainReturnDetails?.train_return_dest || ""}
                onChange={(event) =>
                  handleCoTravellerData("train_return_dest", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>Ticket Amount</label>
              <input
                className="form-control"
                disabled={isChecked}
                placeholder="Ticket Amount"
                value={e.trainDetails?.trainReturnDetails?.ticket_amt || ""}
                onChange={(event) =>
                  handleCoTravellerData("ticket_return_amt", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>PNR No</label>
              <input
                className="form-control"
                placeholder="PNR No"
                disabled={isChecked}
                value={e.trainDetails?.trainReturnDetails?.pnr_return_no || ""}
                onChange={(event) =>
                  handleCoTravellerData("pnr_return_no", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>Arrival Time</label>
              <input
                className="form-control"
                placeholder="Arrival Time"
                disabled={isChecked}
                value={e.trainDetails?.trainReturnDetails?.arrival_return_time || ""}
                onChange={(event) =>
                  handleCoTravellerData("arrival_return_time", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>Departure Time</label>
              <input
                className="form-control"
                placeholder="Departure Time"
                disabled={isChecked}
                value={e.trainDetails?.trainReturnDetails?.depart_return_time || ""}
                onChange={(event) =>
                  handleCoTravellerData("depart_return_time", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>Coach No</label>
              <input
                className="form-control"
                placeholder="Coach No"
                disabled={isChecked}
                value={e.trainDetails?.trainReturnDetails?.train_return_coachNo || ""}
                onChange={(event) =>
                  handleCoTravellerData("train_return_coachNo", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>Berth No</label>
              <input
                className="form-control"
                placeholder="Berth No"
                disabled={isChecked}
                value={e.trainDetails?.trainReturnDetails?.train_return_berthNo || ""}
                onChange={(event) =>
                  handleCoTravellerData("train_return_berthNo", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>Notes</label>
              <textarea
                className="form-control"
                rows={6}
                disabled={isChecked}
                value={e.trainDetails?.trainReturnDetails?.return_Notes || ""}
                onChange={(event) =>
                  handleCoTravellerData("return_Notes", event.target.value, i)
                }
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    ));
  };
  const renderOnwardCoTravellers = () => {
    return trainbooking.coTravellers.map((e, i) => (
      <div className="accordion mb-1" id={`accordion${i}`} key={`cot-${i}`}>
        <div className="card">
          <div className="card-header" id={`heading${i}`}>
            <h2 className="mb-0">
              <button
                className="btn btn-link btn-block text-left text-capitalize"
                type="button"
                data-toggle="collapse"
                data-target={`#collapse${i}`}
                aria-expanded="true"
                aria-controls={`collapse${i}`}
                onClick={() =>
                  accIndex.accIndexTrain == i
                    ? setAccIndex({ ...accIndex, accIndexTrain: -1 })
                    : setAccIndex({ ...accIndex, accIndexTrain: i })
                }
              >
                {e.name || `Co-Traveller ${i + 1}`}{" "}
                {e.gender ? `(${e.gender})` : ""}
              </button>
            </h2>
          </div>

          <div
            id={`collapse${i}`}
            className={`collapse p-3 ${
              accIndex.accIndexTrain == i ? "show" : ""
            }`}
            aria-labelledby={`heading${i}`}
            data-parent={`#accordion${i}`}
          >
            <div className="form-group">
              <label>Traveller Name</label>
              <input
                className="form-control"
                placeholder="traveller name"
                value={e.trainDetails?.trainOnwardDetails?.traveller_name || ""}
                onChange={(event) =>
                  handleCoTravellerData("traveller_name", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>Date Of Travel</label>
              <div>
                <DatePicker
                  selected={
                    e.trainDetails?.trainOnwardDetails?.travel_date === undefined
                      ? ""
                      : new Date(e.trainDetails?.trainOnwardDetails?.travel_date)
                  }
                  onChange={(date) =>
                    handleCoTravellerData("travel_date", date, i)
                  }
                  dateFormat={"dd/MM/yyyy"}
                  minDate={new Date()}
                  placeholderText="select a date"
                />
              </div>
            </div>
            <div className="form-group">
              <label>Train Name</label>
              <input
                className="form-control"
                placeholder="train name"
                value={e.trainDetails?.trainOnwardDetails?.train_name || ""}
                onChange={(event) =>
                  handleCoTravellerData("train_name", event.target.value, i)
                }
              />
            </div>
            <div className="form-group react-select-custom">
              <label>Vendor</label>
              <Select
                placeholder="Vendor Name"
                noOptionsMessage={() => "No Vendors Found"}
                value={options.find((itx) => {
                  return itx.value === e.trainDetails?.trainOnwardDetails?.vendor_name;
                })}
                onChange={(event) => {
                  handleCoTravellerData("vendor_name", event, i);
                }}
                options={options}
              />
            </div>
            <div className="form-group">
              <label>Class</label>
              <select
                className="form-control"
                value={e.trainDetails?.trainOnwardDetails?.seat_class}
                onChange={(event) =>
                  handleCoTravellerData("seat_class", event.target.value, i)
                }
              >
                <option value="Choose Class Type">Choose Class Type</option>
                <option value="Seater">Seater</option>
                <option value="Sleeper">Sleeper</option>
                <option value="2AC">2AC</option>
                <option value="3AC">3AC</option>
                <option value="1AC">1AC</option>
              </select>
            </div>
            <div className="form-group">
              <label>Source</label>
              <input
                className="form-control"
                placeholder="Source"
                value={e.trainDetails?.trainOnwardDetails?.train_source || ""}
                onChange={(event) =>
                  handleCoTravellerData("train_source", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>Destination</label>
              <input
                className="form-control"
                placeholder="Destination"
                value={e.trainDetails?.trainOnwardDetails?.train_dest || ""}
                onChange={(event) =>
                  handleCoTravellerData("train_dest", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>Ticket Amount</label>
              <input
                className="form-control"
                placeholder="Ticket Amount"
                value={e.trainDetails?.trainOnwardDetails?.ticket_amt || ""}
                onChange={(event) =>
                  handleCoTravellerData("ticket_amt", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>PNR No</label>
              <input
                className="form-control"
                placeholder="PNR No"
                value={e.trainDetails?.trainOnwardDetails?.pnr_no || ""}
                onChange={(event) =>
                  handleCoTravellerData("pnr_no", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>Arrival Time</label>
              <input
                className="form-control"
                placeholder="Arrival Time"
                value={e.trainDetails?.trainOnwardDetails?.arrival_time || ""}
                onChange={(event) =>
                  handleCoTravellerData("arrival_time", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>Departure Time</label>
              <input
                className="form-control"
                placeholder="Departure Time"
                value={e.trainDetails?.trainOnwardDetails?.depart_time || ""}
                onChange={(event) =>
                  handleCoTravellerData("depart_time", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>Coach No</label>
              <input
                className="form-control"
                placeholder="Coach No"
                value={e.trainDetails?.trainOnwardDetails?.train_coachNo || ""}
                onChange={(event) =>
                  handleCoTravellerData("train_coachNo", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>Berth No</label>
              <input
                className="form-control"
                placeholder="Berth No"
                value={e.trainDetails?.trainOnwardDetails?.train_berthNo || ""}
                onChange={(event) =>
                  handleCoTravellerData("train_berthNo", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>Notes</label>
              <textarea
                className="form-control"
                rows={6}
                value={e.trainDetails?.trainOnwardDetails?.Notes || ""}
                onChange={(event) =>
                  handleCoTravellerData("Notes", event.target.value, i)
                }
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    ));
  };
  return (
    <Modal show={show.showTrainModal} onHide={() => closeModal()} size="md">
      <Modal.Header closeButton>
        <Modal.Title>Update Train Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs activeKey={Activekey} onSelect={(k) => setActiveKey(k)}>
          <Tab eventKey="onward" title="Onward">
            <Form>
              <div className="row mt-3">
                <div className="col">
                  <div className="form-group">
                    <label>Traveller Name</label>
                    <input
                      className="form-control"
                      placeholder="traveller name"
                      value={
                        trainbooking.trainDetails?.trainOnwardDetails?.traveller_name
                      }
                      onChange={(event) =>
                        handleCustomerTrainDetailsChange(
                          "traveller_name",
                          event.target.value
                        )
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label>Date Of Travel</label>
                    <div>
                      <DatePicker
                        selected={
                          trainbooking.trainDetails?.trainOnwardDetails?.travel_date === undefined
                            ? ""
                            : new Date(
                                trainbooking.trainDetails?.trainOnwardDetails?.travel_date
                              )
                        }
                        onChange={(date) =>
                          handleCustomerTrainDetailsChange("travel_date", date)
                        }
                        dateFormat={"dd/MM/yyyy"}
                        minDate={new Date()}
                        placeholderText="select a date"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Train Name</label>
                    <input
                      className="form-control"
                      placeholder="train name"
                      value={
                        trainbooking.trainDetails?.trainOnwardDetails?.train_name
                      }
                      onChange={(event) =>
                        handleCustomerTrainDetailsChange(
                          "train_name",
                          event.target.value
                        )
                      }
                    />
                  </div>
                  <div className="form-group react-select-custom">
                    <label>Vendor</label>
                    <Select
                      placeholder="Vendor Name"
                      noOptionsMessage={() => "No Vendors Found"}
                      value={options.find((itx) => {
                        return (
                          itx.value ===
                          trainbooking.trainDetails?.trainOnwardDetails?.vendor_name
                        );
                      })}
                      onChange={(event) => {
                        handleCustomerTrainDetailsChange("vendor_name", event);
                      }}
                      options={options}
                    />
                  </div>
                  <div className="form-group">
                    <label>Class</label>
                    <select
                      className="form-control"
                      value={
                        trainbooking.trainDetails?.trainOnwardDetails?.seat_class
                      }
                      onChange={(event) =>
                        handleCustomerTrainDetailsChange(
                          "seat_class",
                          event.target.value
                        )
                      }
                    >
                      <option value="Choose Class Type">
                        Choose Class Type
                      </option>
                      <option value="Seater">Seater</option>
                      <option value="Sleeper">Sleeper</option>
                      <option value="2AC">2AC</option>
                      <option value="3AC">3AC</option>
                      <option value="1AC">1AC</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label>Source</label>
                    <input
                      className="form-control"
                      placeholder="Source"
                      value={
                        trainbooking.trainDetails?.trainOnwardDetails?.train_source
                      }
                      onChange={(event) =>
                        handleCustomerTrainDetailsChange(
                          "train_source",
                          event.target.value
                        )
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label>Destination</label>
                    <input
                      className="form-control"
                      placeholder="Destination"
                      value={
                        trainbooking.trainDetails?.trainOnwardDetails?.train_dest
                      }
                      onChange={(event) =>
                        handleCustomerTrainDetailsChange(
                          "train_dest",
                          event.target.value
                        )
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label>Ticket Amount</label>
                    <input
                      className="form-control"
                      placeholder="Ticket Amount"
                      value={
                        trainbooking.trainDetails?.trainOnwardDetails?.ticket_amt
                      }
                      onChange={(event) =>
                        handleCustomerTrainDetailsChange(
                          "ticket_amt",
                          event.target.value
                        )
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label>PNR No</label>
                    <input
                      className="form-control"
                      placeholder="PNR No"
                      value={
                        trainbooking.trainDetails?.trainOnwardDetails?.pnr_no
                      }
                      onChange={(event) =>
                        handleCustomerTrainDetailsChange(
                          "pnr_no",
                          event.target.value
                        )
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label>Arrival Time</label>
                    <input
                      className="form-control"
                      placeholder="Arrival Time"
                      value={
                        trainbooking.trainDetails?.trainOnwardDetails?.arrival_time
                      }
                      onChange={(event) =>
                        handleCustomerTrainDetailsChange(
                          "arrival_time",
                          event.target.value
                        )
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label>Departure Time</label>
                    <input
                      className="form-control"
                      placeholder="Departure Time"
                      value={
                        trainbooking.trainDetails?.trainOnwardDetails?.depart_time
                      }
                      onChange={(event) =>
                        handleCustomerTrainDetailsChange(
                          "depart_time",
                          event.target.value
                        )
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label>Coach No</label>
                    <input
                      className="form-control"
                      placeholder="Coach No"
                      value={
                        trainbooking.trainDetails?.trainOnwardDetails?.train_coachNo
                      }
                      onChange={(event) =>
                        handleCustomerTrainDetailsChange(
                          "train_coachNo",
                          event.target.value
                        )
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label>Berth No</label>
                    <input
                      className="form-control"
                      placeholder="Berth No"
                      value={
                        trainbooking.trainDetails?.trainOnwardDetails?.train_berthNo
                      }
                      onChange={(event) =>
                        handleCustomerTrainDetailsChange(
                          "train_berthNo",
                          event.target.value
                        )
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label>Notes</label>
                    <textarea
                      className="form-control"
                      rows={6}
                      value={
                        trainbooking.trainDetails?.trainOnwardDetails?.Notes
                      }
                      onChange={(event) =>
                        handleCustomerTrainDetailsChange(
                          "Notes",
                          event.target.value
                        )
                      }
                    ></textarea>
                  </div>
                </div>
              </div>
              {trainbooking.coTravellers.length > 0 && (
                <div>{renderOnwardCoTravellers()}</div>
              )}
            </Form>
          </Tab>
          <Tab eventKey="return" title="Return">
            <Form>
              <div class="form-check">
                <input
                  type="checkbox"
                  class="form-check-input"
                  checked={isChecked}
                  onChange={(e) => setIsChecked(e.target.checked)}
                />
                <label class="form-check-label">Return N/A</label>
              </div>
              <div className="row mt-3">
                <div className="col">
                  <div className="form-group">
                    <label>Traveller Name</label>
                    <input
                      className="form-control"
                      disabled={isChecked}
                      placeholder="traveller name"
                      value={
                        trainbooking.trainDetails?.trainReturnDetails
                          ?.traveller_return_name
                      }
                      onChange={(event) =>
                        handleCustomerTrainDetailsChange(
                          "traveller_return_name",
                          event.target.value
                        )
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label>Date Of Travel</label>
                    <div>
                      <DatePicker
                        selected={
                          trainbooking.trainDetails?.trainReturnDetails
                            ?.travel_return_date === undefined
                            ? ""
                            : new Date(
                                trainbooking.trainDetails?.trainReturnDetails?.travel_return_date
                              )
                        }
                        onChange={(date) =>
                          handleCustomerTrainDetailsChange(
                            "travel_return_date",
                            date
                          )
                        }
                        dateFormat={"dd/MM/yyyy"}
                        disabled={isChecked}
                        minDate={new Date()}
                        placeholderText="select a date"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Train Name</label>
                    <input
                      className="form-control"
                      placeholder="train name"
                      disabled={isChecked}
                      value={
                        trainbooking.trainDetails?.trainReturnDetails
                          ?.train_return_name
                      }
                      onChange={(event) =>
                        handleCustomerTrainDetailsChange(
                          "train_return_name",
                          event.target.value
                        )
                      }
                    />
                  </div>
                  <div className="form-group react-select-custom">
                    <label>Vendor</label>
                    <Select
                      placeholder="Vendor Name"
                      noOptionsMessage={() => "No Vendors Found"}
                      isDisabled={isChecked}
                      value={options.find((itx) => {
                        return (
                          itx.value ===
                          trainbooking.trainDetails?.trainReturnDetails
                            ?.vendor_return_name
                        );
                      })}
                      onChange={(event) => {
                        handleCustomerTrainDetailsChange(
                          "vendor_return_name",
                          event
                        );
                      }}
                      options={options}
                    />
                  </div>
                  <div className="form-group">
                    <label>Class</label>
                    <select
                      className="form-control"
                      value={
                        trainbooking.trainDetails?.trainReturnDetails
                          ?.seat_return_class
                      }
                      disabled={isChecked}
                      onChange={(event) =>
                        handleCustomerTrainDetailsChange(
                          "seat_return_class",
                          event.target.value
                        )
                      }
                    >
                      <option value="Choose Class Type">
                        Choose Class Type
                      </option>
                      <option value="Seater">Seater</option>
                      <option value="Sleeper">Sleeper</option>
                      <option value="2AC">2AC</option>
                      <option value="3AC">3AC</option>
                      <option value="1AC">1AC</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label>Source</label>
                    <input
                      className="form-control"
                      placeholder="Source"
                      value={
                        trainbooking.trainDetails?.trainReturnDetails
                          ?.train_return_source
                      }
                      disabled={isChecked}
                      onChange={(event) =>
                        handleCustomerTrainDetailsChange(
                          "train_return_source",
                          event.target.value
                        )
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label>Destination</label>
                    <input
                      className="form-control"
                      placeholder="Destination"
                      value={
                        trainbooking.trainDetails?.trainReturnDetails
                          ?.train_return_dest
                      }
                      disabled={isChecked}
                      onChange={(event) =>
                        handleCustomerTrainDetailsChange(
                          "train_return_dest",
                          event.target.value
                        )
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label>Ticket Amount</label>
                    <input
                      className="form-control"
                      placeholder="Ticket Amount"
                      value={
                        trainbooking.trainDetails?.trainReturnDetails
                          ?.ticket_return_amt
                      }
                      disabled={isChecked}
                      onChange={(event) =>
                        handleCustomerTrainDetailsChange(
                          "ticket_return_amt",
                          event.target.value
                        )
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label>PNR No</label>
                    <input
                      className="form-control"
                      placeholder="PNR No"
                      value={
                        trainbooking.trainDetails?.trainReturnDetails
                          ?.pnr_return_no
                      }
                      disabled={isChecked}
                      onChange={(event) =>
                        handleCustomerTrainDetailsChange(
                          "pnr_return_no",
                          event.target.value
                        )
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label>Arrival Time</label>
                    <input
                      className="form-control"
                      placeholder="Arrival Time"
                      value={
                        trainbooking.trainDetails?.trainReturnDetails
                          ?.arrival_return_time
                      }
                      disabled={isChecked}
                      onChange={(event) =>
                        handleCustomerTrainDetailsChange(
                          "arrival_return_time",
                          event.target.value
                        )
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label>Departure Time</label>
                    <input
                      className="form-control"
                      placeholder="Departure Time"
                      value={
                        trainbooking.trainDetails?.trainReturnDetails
                          ?.depart_return_time
                      }
                      disabled={isChecked}
                      onChange={(event) =>
                        handleCustomerTrainDetailsChange(
                          "depart_return_time",
                          event.target.value
                        )
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label>Coach No</label>
                    <input
                      className="form-control"
                      placeholder="Coach No"
                      value={
                        trainbooking.trainDetails?.trainReturnDetails
                          ?.train_return_coachNo
                      }
                      disabled={isChecked}
                      onChange={(event) =>
                        handleCustomerTrainDetailsChange(
                          "train_return_coachNo",
                          event.target.value
                        )
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label>Berth No</label>
                    <input
                      className="form-control"
                      placeholder="Berth No"
                      value={
                        trainbooking.trainDetails?.trainReturnDetails
                          ?.train_return_berthNo
                      }
                      disabled={isChecked}
                      onChange={(event) =>
                        handleCustomerTrainDetailsChange(
                          "train_return_berthNo",
                          event.target.value
                        )
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label>Notes</label>
                    <textarea
                      className="form-control"
                      rows={6}
                      value={
                        trainbooking.trainDetails?.trainReturnDetails
                          ?.return_Notes
                      }
                      disabled={isChecked}
                      onChange={(event) =>
                        handleCustomerTrainDetailsChange(
                          "return_Notes",
                          event.target.value
                        )
                      }
                    ></textarea>
                  </div>
                </div>
              </div>
              {trainbooking.coTravellers.length > 0 && (
                <div>{renderReturnCoTravellers()}</div>
              )}
            </Form>
          </Tab>
        </Tabs>
      </Modal.Body>
      <Modal.Footer>
        <button
          className={
            isChecked && Activekey === "return"
              ? "d-none"
              : "btn btn-sm btn-danger"
          }
          onClick={() => closeModal()}
        >
          Close
        </button>
        <button
          className={
            isChecked && Activekey === "return"
              ? "d-none"
              : "btn btn-sm btn-primary"
          }
          onClick={() => handleModalChanges()}
        >
          Save Changes
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default TrainModal;
