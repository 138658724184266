import React, { useState, useEffect } from "react";
import moment from "moment";
import swal from "sweetalert";
import { OverlayTrigger, Tooltip, Modal, Form } from "react-bootstrap";
import Select from "react-select";
import { useSelector } from "react-redux";
import { useLocation, Link, useParams } from "react-router-dom";
import ReactExport from "react-export-excel";

import { packagesService } from "../../_services/packages.service";
import { bookingsService } from "../../_services/bookings.service";
import { userService } from "../../_services/user.service";
import { tripsService } from "../../_services/trips.service";

import UpdateInfo from "./UpdateInfo";
import UpdatePayment from "./UpdatePayment";
import { ExpenseModal } from "./ExpenseModal";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const TripReports = ({ location }) => {
  const { search } = useLocation();
  const [vendors, setVendor] = useState([]);
  const [data, setData] = useState({
    packages: [],
    trips: [],
  });

  const [gaStaff, setStaff] = useState({
    trekker: [],
    sales: [],
  });

  const [loading, setLoading] = useState(true);

  const [tripDetails, setTripDetails] = useState({
    bookings: [],
    details: {},
    client: {
      customerDetails: {},
    },
  });

  const authData = useSelector((state) => state.authentication);

  const [filter, setFilter] = useState({
    package: "",
    trip: {},
    tripId: "",
    opsManger: null,
    trekLeader: null,
  });

  const [showUpdatePayment, toggleUpdatePayment] = useState(false);
  const [vendorExpenses, setVendorExpenses] = useState([]);
  const [showUpdate, toggleUpdateInfo] = useState(false);
  const [showExpense, toggleExpense] = useState(false);

  useEffect(() => {
    if (!filter.tripId) {
      setTripDetails({
        bookings: [],
        details: {},
        client: {
          customerDetails: {},
        },
      });
    } else {
      let _trip = data.trips.filter((e) => e.id === filter.tripId)[0] || {};
      setFilter({
        ...filter,
        opsManger:
          gaStaff.sales.filter((e) => e.id == _trip.opsManagerId)[0] || null,
        trekLeader:
          gaStaff.trekker.filter((e) => e.id == _trip.trekLeaderId)[0] || null,
      });
      getBookings(_trip);
    }
  }, [filter.tripId]);

  const getVendorDetails = () => {
    userService.getByRole("vendor", {}).then((resp) => {
      setVendor(resp);
    });
  };

  useEffect(() => {
    getGAStaff();
    getVendorDetails();
  }, []);
  useEffect(() => {
    CalculateExpense();
  }, [vendors, tripDetails.bookings]);
  useEffect(() => {
    getBookings();
  }, [showUpdate]);

  const getPackages = (resetFilters = true) => {
    packagesService.list({ order: "name ASC" }).then((resp = []) => {
      let params = Object.fromEntries(new URLSearchParams(search));
      let _trips = (resp[0] && resp[0].trips) || [];

      if (Object.keys(params).length) {
        let _package = resp.filter((e) => e.id == params.package)[0];
        _trips = _package.trips;
      }

      setData({
        packages: resp,
        trips: _trips,
      });

      if (resp.length && resetFilters && !Object.keys(params).length) {
        setFilter({
          ...filter,
          package: resp[0],
          tripId: resp[0].trips[0].id,
          trip: resp[0].trips[0],
        });
      } else if (resp.length && resetFilters && Object.keys(params).length) {
        let _package = resp.filter((e) => e.id == params.package)[0];
        let _trip = _package.trips.filter((e) => e.id == params.trip)[0];
        setFilter({
          ...filter,
          package: _package,
          tripId: _trip.id,
          trip: _trip,
        });
      }

      setLoading(false);
    });
  };

  const getBookings = (details = {}) => {
    bookingsService
      .list({
        limit: 10000,
        where: { status: "SUCCESS", tripId: filter.tripId },
        include: ["salesExecutive"],
        order: "bookingDate DESC",
      })
      .then((resp) => {
        if (!Object.keys(details).length) {
          details = data.trips.filter((e) => e.id === filter.tripId)[0] || {};
        }

        setTripDetails({
          details,
          bookings: resp,
          client: {
            customerDetails: {},
          },
        });
      });
  };

  const getGAStaff = () => {
    Promise.allSettled([
      userService.getByRole("sales", {}),
      userService.getByRole("trekker", {}),
    ])
      .then((resp) => {
        let _data = resp.map((e) => e.value);
        setStaff({
          sales: _data[0] || [],
          trekker: _data[1] || [],
        });

        getPackages();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const confirmAddTrekLeader = (value) => {
    swal({
      title: "Alert !",
      text: `Are you sure to ${
        tripDetails.details.trekLeaderId ? "modify" : "add"
      } trek leader to this trip ?`,
      buttons: true,
      dangerMode: !!tripDetails.details.trekLeaderId,
    }).then((isOk) => {
      if (!isOk) {
        return false;
      }

      setFilter({
        ...filter,
        trekLeader: gaStaff.trekker.filter((e) => e.id == value)[0] || null,
      });

      let _data = Object.assign({}, tripDetails.details, {
        trekLeaderId: value,
      });

      updateTrip(_data);
    });
  };

  const updateTrip = (data) => {
    tripsService.update(data).then((resp) => {
      getPackages(false);
    });
  };

  const sendPaymentLink = (booking) => {
    const data = {
      bookingId: booking.id,
      pendingAmount: booking.dueAmount,
      remarks: "",
      email: "pchvramesh@gmail.com" || booking.emailId,
    };

    bookingsService.generateSendPaymentLink(data).then((resp) => {
      swal({
        title: "Payment link sent successfully",
        text: resp.paymentLink,
        icon: "success",
      });
    });
  };

  const showUpdateInfo = (user) => {
    let _user = { ...user };

    toggleUpdateInfo(true);

    setTripDetails({
      ...tripDetails,
      client: { ..._user },
    });
  };

  const getTravellersCount = () => {
    return tripDetails.bookings
      ? tripDetails.bookings.map((e) => e.noOfUsers).reduce((p, c) => p + c)
      : 0;
  };

  const getAmountReceived = () => {
    return tripDetails.bookings
      ? tripDetails.bookings.map((e) => e.paidAmount).reduce((p, c) => p + c)
      : 0;
  };

  const getAmountPending = () => {
    return tripDetails.bookings
      ? tripDetails.bookings.map((e) => e.dueAmount).reduce((p, c) => p + c)
      : 0;
  };

  const showPaymentUpdate = (user) => {
    let _user = { ...user };
    setTripDetails({
      ...tripDetails,
      client: { ..._user },
    });
    toggleUpdatePayment(true);
  };
  const CalculateExpense = () => {
    const calculatedExpense = [];
    for (const vendorName of vendors) {
      const { name, username } = vendorName;
      const vendorExpense = {
        vendor_name: name || username,
        expense: 0,
      };

      for (const booking of tripDetails.bookings) {
        const {
          trainDetails,
          busDetails,
          vehicleDetails,
          stayDetails,
          coTravellers,
        } = booking;
        if (
          busDetails &&
          busDetails.vendor_name === vendorExpense.vendor_name
        ) {
          vendorExpense.expense += parseInt(busDetails.amount);
        }
        if (
          trainDetails &&
          trainDetails?.trainOnwardDetails?.vendor_name ===
            vendorExpense.vendor_name
        ) {
          vendorExpense.expense += parseInt(
            trainDetails.trainOnwardDetails.ticket_amt
          );
        }
        if (
          vehicleDetails &&
          vehicleDetails.vendor_name === vendorExpense.vendor_name
        ) {
          vendorExpense.expense += parseInt(vehicleDetails.amount);
        }
        if (
          stayDetails &&
          stayDetails.owner_name === vendorExpense.vendor_name
        ) {
          vendorExpense.expense += parseInt(stayDetails.amount);
        }
        if (
          trainDetails &&
          trainDetails?.trainReturnDetails?.vendor_return_name ===
            vendorExpense.vendor_name
        ) {
          vendorExpense.expense += parseInt(
            trainDetails.trainReturnDetails.ticket_return_amt
          );
        }
        for (const traveller of coTravellers) {
          const { trainDetails, stayDetails, busDetails, vehicleDetails } =
            traveller;

          if (
            busDetails &&
            busDetails.vendor_name === vendorExpense.vendor_name
          ) {
            vendorExpense.expense += parseInt(busDetails.amount);
          }
          if (
            trainDetails &&
            trainDetails?.trainOnwardDetails?.vendor_name ===
              vendorExpense.vendor_name
          ) {
            vendorExpense.expense += parseInt(
              trainDetails.trainOnwardDetails.ticket_amt
            );
          }
          if (
            vehicleDetails &&
            vehicleDetails.vendor_name === vendorExpense.vendor_name
          ) {
            vendorExpense.expense += parseInt(vehicleDetails.amount);
          }
          if (
            stayDetails &&
            stayDetails.owner_name === vendorExpense.vendor_name
          ) {
            vendorExpense.expense += parseInt(stayDetails.amount);
          }
          if (
            trainDetails &&
            trainDetails?.trainReturnDetails?.vendor_return_name ===
              vendorExpense.vendor_name
          ) {
            vendorExpense.expense += parseInt(
              trainDetails.trainReturnDetails.ticket_return_amt
            );
          }
        }
      }
      calculatedExpense.push({
        vendor_name: vendorExpense.vendor_name,
        expense: vendorExpense.expense,
      });
    }
    setVendorExpenses(calculatedExpense);
  };
  const getExpenseCalculator = () => {
    let totalExpense = 0;

    for (const booking of tripDetails.bookings) {
      const {
        trainDetails,
        stayDetails,
        busDetails,
        vehicleDetails,
        addonDetails,
        coTravellers,
      } = booking;

      if (trainDetails && trainDetails?.trainOnwardDetails?.ticket_amt) {
        totalExpense += parseInt(trainDetails.trainOnwardDetails.ticket_amt);
      }

      if (stayDetails && stayDetails.amount) {
        totalExpense += parseInt(stayDetails.amount);
      }

      if (busDetails && busDetails.amount) {
        totalExpense += parseInt(busDetails.amount);
      }
      if (vehicleDetails && vehicleDetails.amount) {
        totalExpense += parseInt(vehicleDetails.amount);
      }
      if (addonDetails && addonDetails.addon_amt) {
        totalExpense += parseInt(addonDetails.addon_amt);
      }
      if (trainDetails && trainDetails?.trainReturnDetails?.ticket_return_amt) {
        totalExpense += parseInt(trainDetails.trainReturnDetails.ticket_return_amt);
      }
      if (coTravellers && coTravellers.length > 0) {
        for (const coTraveller of coTravellers) {
          const { trainDetails, stayDetails, busDetails, vehicleDetails,addonDetails } =
            coTraveller;
          if (trainDetails && trainDetails?.trainOnwardDetails?.ticket_amt) {
            totalExpense += parseInt(trainDetails.trainOnwardDetails.ticket_amt);
          }
          if (stayDetails && stayDetails.amount) {
            totalExpense += parseInt(stayDetails.amount);
          }

          if (busDetails && busDetails.amount) {
            totalExpense += parseInt(busDetails.amount);
          }
        
          if (vehicleDetails && vehicleDetails.amount) {
            totalExpense += parseInt(vehicleDetails.amount);
          }
          if (addonDetails && addonDetails.addon_amt) {
            totalExpense += parseInt(addonDetails.addon_amt);
          }
          if (trainDetails && trainDetails?.trainReturnDetails?.ticket_return_amt) {
            totalExpense += parseInt(trainDetails.trainReturnDetails.ticket_return_amt);
          }
        }
      }
    }
    return totalExpense;
  };
  const renderDetails = () => {
    return !loading && !tripDetails?.bookings.length ? (
      <div>No Details found</div>
    ) : (
      <>
        <div className="row">
          <div className="col-3">
            <div className="card bg-primary text-white">
              <div className="card-body d-flex justify-content-between">
                <h4>Trip Capacity / Available</h4>
                <b>
                  {tripDetails?.details?.strengthLimit} /{" "}
                  {tripDetails?.details?.strengthLimit - getTravellersCount()}
                </b>
              </div>
            </div>
          </div>

          <div className="col-3">
            <div className="card bg-warning">
              <div
                className="card-body d-flex justify-content-between"
                onClick={() => toggleExpense(!showExpense)}
              >
                <h4>Total Expenses</h4>
                <b>{getExpenseCalculator()}</b>
              </div>
            </div>
          </div>

          <div className="col-3">
            <div className="card bg-success">
              <div className="card-body d-flex justify-content-between">
                <h4>Total Travellers / Bookings</h4>
                <b>
                  {getTravellersCount()} / {tripDetails.bookings.length}
                </b>
              </div>
            </div>
          </div>
          <div className="col-3">
            <div className="card bg-success">
              <div className="card-body d-flex justify-content-between">
                <h4>Amount Received</h4>
                <b>{getAmountReceived()}</b>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          {getAmountPending() == 0 ? (
            <div className="col-4">
              <div className="card bg-primary">
                <div className="card-body d-flex justify-content-between">
                  <h5>Total Bookings</h5>
                  <b>{tripDetails?.bookings.length}</b>
                </div>
              </div>
            </div>
          ) : null}

          {getAmountPending() > 0 ? (
            <div className="col-4">
              <div className="card bg-danger">
                <div className="card-body d-flex justify-content-between text-white">
                  <h5>Amount Pending</h5>
                  <b>{getAmountPending()}</b>
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <div className="row mt-3">
          <div className="col-sm-12 table-responsive">
            <table className="table table-sm">
              <thead>
                <tr className="bg-warning">
                  <th scope="col">Order ID</th>
                  <th scope="col">Name</th>
                  <th scope="col">Contact Number</th>
                  <th scope="col"># Travellers</th>
                  <th scope="col">Booking Date</th>
                  <th scope="col">Paid Amount</th>
                  <th scope="col">Due Amount</th>
                  <th scope="col">Total Amount</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {tripDetails.bookings.length ? (
                  tripDetails.bookings.map((e, i) => (
                    <tr key={i}>
                      <td scope="row">
                        <div
                          className={`d-flex flex-column text-center ${
                            e.dueAmount > 0
                              ? "text-danger font-weight-bold"
                              : ""
                          }`}
                        >
                          <span>{e.orderId}</span>
                          <span>{e.salesExecutive?.username}</span>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex flex-column">
                          <span>{e.customerDetails.name || ""}</span>
                          <span>{e.emailId || ""}</span>
                        </div>
                      </td>
                      <td>{e.primaryContact}</td>
                      <td>{e.noOfUsers}</td>
                      <td>
                        <div className="d-flex flex-column">
                          <span>{moment(e.bookingDate).format("LL")}</span>
                          <span>{moment(e.bookingDate).format("hh:mm A")}</span>
                        </div>
                      </td>
                      <td>{e.paidAmount}</td>
                      <td
                        className={`${
                          e.dueAmount > 0 ? "text-danger font-weight-bold" : ""
                        }`}
                      >
                        {e.dueAmount}
                      </td>
                      <td>{e.totalAmount}</td>
                      <td>
                        {e.dueAmount > 0 ? (
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Send Payment Link</Tooltip>}
                          >
                            <button
                              onClick={() => sendPaymentLink(e)}
                              className="btn btn-primary mr-3"
                            >
                              <i className="fa fa-rupee"></i>
                            </button>
                          </OverlayTrigger>
                        ) : null}

                        {authData.user.role == "admin" ||
                        authData.user.role == "sales" ? (
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Update Information</Tooltip>}
                          >
                            <button
                              onClick={() => showUpdateInfo(e)}
                              className="btn btn-success mr-3"
                            >
                              <i className="fa fa-edit"></i>
                            </button>
                          </OverlayTrigger>
                        ) : null}

                        {e.dueAmount > 0 || e.paymentHistory ? (
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Payment History</Tooltip>}
                          >
                            <button
                              onClick={() => showPaymentUpdate(e)}
                              className="btn btn-success"
                            >
                              <i className="fa fa-file"></i>
                            </button>
                          </OverlayTrigger>
                        ) : null}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4}>No Booking found for this trip</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  };

  const confirmOpsManager = (value) => {
    swal({
      title: "Alert !",
      text: `Are you sure to ${
        tripDetails.details.opsManagerId ? "modify" : "add"
      } ops manager to this trip ?`,
      buttons: true,
      dangerMode: !!tripDetails.details.opsManagerId,
    }).then((isOk) => {
      if (!isOk) {
        return false;
      }
      setFilter({
        ...filter,
        opsManger: gaStaff.sales.filter((e) => e.id == value)[0] || null,
      });

      let _data = Object.assign({}, tripDetails.details, {
        opsManagerId: value,
      });

      updateTrip(_data);
    });
  };

  const getData = () => {
    if (!tripDetails.bookings || !tripDetails.bookings.length) {
      return false;
    }

    let data = tripDetails.bookings.map((e) => {
      let _obj = {
        name: e.customerDetails.name,
        gender: e.customerDetails.gender,
        age: e.customerDetails.age || "",
        phone: e.customerDetails.mobileNumber,
        boardingPoint: e.boardingPoint,
        paymentStatus: e.fullPaymentCompleted ? "DONE" : "PARTIAL",
        trip_notes: "",
        client_notes: "",
      };

      if (!e.coTravellers) {
        e.coTravellers = [];
      }

      let _data = e.coTravellers
        .filter((e) => e.name)
        .map((e) => {
          return {
            name: e.name,
            gender: e.gender,
            age: e.age || "",
            phone: e.phone,
            boardingPoint: e.boardingPoint,
            paymentStatus: _obj.paymentStatus,
            trip_notes: e.teamNotes,
            client_notes: e.clientNotes,
          };
        });

      _data.unshift(_obj);

      _data = _data.map((e, i) => {
        e.sno = i + 1;
        return e;
      });

      return _data;
    });

    data = [].concat.apply([], data);

    return data;
  };

  return (
    <div>
      <div className="row page-title-header">
        <div className="col-sm-10">
          <div className="page-header">
            <div className="d-flex">
              <h4 className="page-title">Trip Report</h4>
              <div className="ml-4">
                <ExcelFile
                  filename={`TrekLeaderReport`}
                  element={
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Export Trek Leader Report</Tooltip>}
                    >
                      <button className="btn btn-sm btn-primary btn-icon">
                        <i className="fa fa-file-excel-o"></i>
                      </button>
                    </OverlayTrigger>
                  }
                >
                  <ExcelSheet data={getData()} name="TrekLeaderReport">
                    <ExcelColumn label="S.No" value="sno" />
                    <ExcelColumn label="Name" value="name" />
                    <ExcelColumn label="Gender" value="gender" />
                    <ExcelColumn label="Age" value="age" />
                    <ExcelColumn label="Phone" value="phone" />
                    <ExcelColumn label="Boarding Point" value="boardingPoint" />
                    <ExcelColumn label="Payment Status" value="paymentStatus" />
                    <ExcelColumn label="Trip Notes" value="trip_notes" />
                    <ExcelColumn label="Client Notes" value="client_notes" />
                  </ExcelSheet>
                </ExcelFile>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-2">
          <Link to="/ongoing_trips" className="btn btn-primary btn-sm">
            View Ongoing Trips
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 grid-margin">
          <form className="form row">
            <div className="form-group col-sm-3 react-select-custom">
              <label>Package</label>
              <Select
                placeholder="Select Package"
                value={filter.package}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                onChange={(event) => {
                  setFilter({
                    opsManger: null,
                    trekLeader: null,
                    package: event,
                    trip: {},
                    tripId: "",
                  });

                  setData({
                    ...data,
                    trips: event.trips,
                  });
                }}
                options={data.packages}
              />
            </div>
            <div className="form-group col-sm-3 react-select-custom">
              <label htmlFor="tripId">Trip</label>

              <Select
                placeholder="Select Trip"
                value={filter.trip}
                getOptionLabel={(trip) =>
                  trip.name
                    ? `${trip.name}: ${moment(trip.startDate).format(
                        "ll"
                      )} - ${moment(trip.endDate).format("ll")}`
                    : "Select Trip"
                }
                getOptionValue={(option) => option.id}
                onChange={(event) => {
                  setFilter({
                    ...filter,
                    trip: event,
                    tripId: event.id,
                  });
                }}
                options={data.trips}
              />
            </div>
            <div className="form-group col-sm-3">
              <label htmlFor="tripId">Trek Leader</label>

              <Select
                placeholder="Select Trek Leader"
                value={filter.trekLeader}
                getOptionLabel={(s) => s.username}
                getOptionValue={(option) => option.id}
                onChange={(event) => {
                  if (event.id) {
                    confirmAddTrekLeader(event.id);
                  }
                }}
                options={gaStaff.trekker}
              />
            </div>

            <div className="form-group col-sm-3 react-select-custom">
              <label htmlFor="tripId">Ops Manager</label>
              <Select
                placeholder="Select Ops Manager"
                value={filter.opsManger}
                getOptionLabel={(s) => s.username}
                getOptionValue={(option) => option.id}
                onChange={(event) => {
                  if (event.id) {
                    confirmOpsManager(event.id);
                  }
                }}
                options={gaStaff.sales}
              />
            </div>
          </form>
          <div>{loading ? <div>Fetching Trips...</div> : renderDetails()}</div>
        </div>
      </div>

      {showUpdate ? (
        <UpdateInfo
          data={tripDetails.client}
          getBookings={getBookings}
          clearBooking={() => {
            toggleUpdateInfo(false);
            setTripDetails({
              ...tripDetails,
              client: {
                customerDetails: {},
              },
            });
          }}
          updateClientState={(data) => {
            setTripDetails({
              ...tripDetails,
              client: { ...data },
            });
          }}
        />
      ) : null}

      {showUpdatePayment ? (
        <UpdatePayment
          getBookings={getBookings}
          data={tripDetails.client}
          clear={() => {
            toggleUpdatePayment(false);
          }}
        />
      ) : null}
      <ExpenseModal
        show={showExpense}
        setShow={toggleExpense}
        ExpenseList={vendorExpenses}
      />
    </div>
  );
};

export default TripReports;
