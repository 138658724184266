import { tripConstants } from "../_constants";
import { tripsService } from "../_services";
import { bookingsService } from "../_services/bookings.service";
import { alertActions } from "./";

export const tripsActions = {
  add,
  list,
  getById,
  update,
  deleteById,
  replicate,
};

function add(newTrip) {
  return (dispatch) => {
    return tripsService.add(newTrip).then(
      (addedTrip) => {
        dispatch(success(addedTrip));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function success(obj) {
    return { type: tripConstants.NEW_TRIP_SUCCESS, obj };
  }

  function failure(obj) {
    return { type: tripConstants.NEW_TRIP_FAILURE, obj };
  }
}

function list(packageId) {
  return (dispatch) => {
    return tripsService
      .list(packageId)
      .then((trips) => {
        bookingsService
          .list({
            where: { packageId, status: "SUCCESS" },
            fields: ["tripId", "meta", "noOfUsers"],
          })
          .then((bookings) => {
            trips = trips.map((trip) => {
              trip.isFull = false;
              trip.bookings = 0;
              trip.totalTravellers = 0;
              const totalTravellers = bookings
                .filter((booking) => {
                  const f = booking.tripId === trip.id;
                  if (f) trip.bookings++;
                  return f;
                })
                .reduce((acc, curr) => {
                  return acc + curr.noOfUsers;
                }, 0);
              trip.totalTravellers = totalTravellers;
              if (totalTravellers >= trip.strengthLimit) {
                trip.isFull = true;
              }
              return trip;
            });
            dispatch(success(trips));
          })
          .catch((error) => {
            dispatch(failure(error.toString()));
            dispatch(alertActions.error(error.toString()));
          });
        // temp block, will refactor
      })
      .catch((error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      });
  };

  // function request(trips) {
  //   return { type: tripConstants.TRIP_LIST_REQUEST, trips };
  // }

  function success(trips) {
    return { type: tripConstants.TRIP_LIST_SUCCESS, trips };
  }

  function failure(trips) {
    return { type: tripConstants.TRIP_LIST_FAILURE, trips };
  }
}

function getById(id) {
  return (dispatch) => {
    return tripsService.getById(id).then(
      (_package) => dispatch(success(_package)),
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function success(trip) {
    return { type: tripConstants.GET_TRIP_SUCCESS, trip };
  }

  function failure(trip) {
    return { type: tripConstants.GET_TRIP_FAILURE, trip };
  }
}

function update(updateTrip) {
  return (dispatch) => {
    return tripsService.update(updateTrip).then(
      (updateTrip) => dispatch(success(updateTrip)),
      (error) => dispatch(failure(updateTrip, error.toString()))
    );
  };

  // function request(obj) {
  //   return { type: tripConstants.TRIP_UPDATE_REQUEST, obj };
  // }
  function success(obj) {
    return { type: tripConstants.TRIP_UPDATE_SUCCESS, obj };
  }
  function failure(obj) {
    return { type: tripConstants.TRIP_UPDATE_FAILURE, obj };
  }
}

function deleteById(id) {
  return (dispatch) => {
    return tripsService.deleteById(id).then(
      (_package) => dispatch(success(_package)),
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function success(trip) {
    return { type: tripConstants.TRIP_DELETE_SUCCESS, trip };
  }

  function failure(trip) {
    return { type: tripConstants.TRIP_DELETE_FAILURE, trip };
  }
}

function replicate(tripId) {
  return (dispatch) => {
    return tripsService.replicate(tripId).then(
      () => {
        // dispatch(success(addedTrip));
      },
      (error) => {
        // dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  // function success(obj) {
  //   return { type: tripConstants.NEW_TRIP_SUCCESS, obj };
  // }

  // function failure(obj) {
  //   return { type: tripConstants.NEW_TRIP_FAILURE, obj };
  // }
}
