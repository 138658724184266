import config from "./config.json";
import { authHeader } from "../_helpers";
import { userActions } from "../_actions";
import axios from 'axios';

config.apiUrl = process.env.REACT_APP_API_URL

export const packagesService = {
  add,
  list,
  update,
  getById,
  deleteById,
};

async function add(newPackage) {
    let user = JSON.parse(localStorage.getItem('user'));
    const response = await axios.post(`${config.apiUrl}/packages/new`, newPackage, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': user.id
      }
    });
    handleResponse(response);
}

function list(options) {
  options = options || {};
  options = Object.assign(
    {
      limit: 1000,
      skip: 0,
      include: [
        { trips: { costCenters: ["travelType","stayType"] } },
        "packageType",
        "urlType",
        "duration",
        "destination",
        "eventLevel",
      ],
    },
    options
  );

  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${config.apiUrl}/packages?filter=${JSON.stringify(options)}`,
    requestOptions
  ).then(handleResponse);
}

function getById(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${config.apiUrl}/packages/${id}?filter=${JSON.stringify({
      include: [
        "packageType",
        "urlType",
        "duration",
        "destination",
        "eventLevel",
      ],
    })}`,
    requestOptions
  ).then(handleResponse);
}

async function update(updatePackage) {
  let user = JSON.parse(localStorage.getItem('user'));
  const response = await axios.post(`${config.apiUrl}/packages/new`, updatePackage, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': user.id
    }
  });
  handleResponse(response);
}

function deleteById(id) {
  const requestOptions = {
    method: "DELETE",
    headers: { ...authHeader() },
  };

  return fetch(`${config.apiUrl}/packages/${id}`, requestOptions).then(
    handleResponse
  );
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        userActions.logout();
        // location.reload(true);
      }

      const error = (data && data.error) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
