export const tripConstants = {
  NEW_TRIP: "NEW_TRIP",
  NEW_TRIP_SUCCESS: "NEW_TRIP_SUCCESS",
  NEW_TRIP_FAILURE: "NEW_TRIP_FAILURE",

  TRIP_LIST_REQUEST: "TRIP_LIST_REQUEST",
  TRIP_LIST_SUCCESS: "TRIP_LIST_SUCCESS",
  TRIP_LIST_FAILURE: "TRIP_LIST_FAILURE",

  GET_TRIP_SUCCESS: "GET_TRIP_SUCCESS",
  GET_TRIP_FAILURE: "GET_TRIP_FAILURE",

  TRIP_UPDATE_REQUEST: "TRIP_UPDATE_REQUEST",
  TRIP_UPDATE_SUCCESS: "TRIP_UPDATE_SUCCESS",
  TRIP_UPDATE_FAILURE: "TRIP_UPDATE_FAILURE",

  TRIP_DELETE_REQUEST: "TRIP_DELETE_REQUEST",
  TRIP_DELETE_SUCCESS: "TRIP_DELETE_SUCCESS",
  TRIP_DELETE_FAILURE: "TRIP_DELETE_FAILURE",
};
